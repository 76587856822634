import { useEffect, useState } from "react";
import { Navbar } from "../../Components/Navbar/Navbar";

import { ThreeCircles } from "react-loader-spinner";
import classes from "./StockCalculationsPage.module.scss";
import { CALCULATION_DETAILS_ROUTE, STOCK_CALCULATIONS_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";

import { useNavigate } from "react-router";
import useCalculation from "../../hooks/useCalculation";
import { StockCalculationCard } from "../../Components/StockCalculationCard/StockCalculationCard";

export const StockCalculationsPage = () => {
  const [pooledFirms, setPooledFirms] = useState<any[]>([]);

  const navigate = useNavigate();

  const { getCalculations, calculationsLoading } = useCalculation();

  useEffect(() => {
    getCalculations().then((calcs) => {
      // Pool all firms into a single array with their calculation IDs and notes
      const firms = calcs.flatMap((calc: any) =>
        calc.firms.map((firm: any) => ({
          _stockCalculationId: calc._stockCalculationId,
          note: calc.note,
          firm,
        }))
      );

      // Sort pooled firms by firm name and then by date
      const sortedFirms = firms.sort((a: any, b: any) => {
        const nameA = (a.firm.firmName || "").toLowerCase();
        const nameB = (b.firm.firmName || "").toLowerCase();

        // If firm names are different, sort by name
        if (nameA !== nameB) {
          return nameA.localeCompare(nameB);
        }

        // If firm names are the same, sort by date
        const dateA = new Date(a.firm.date || 0).getTime();
        const dateB = new Date(b.firm.date || 0).getTime();
        return dateA - dateB;
      });

      setPooledFirms(sortedFirms);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Navbar />
      <div className={classes.stockCalculationsWrapper}>
        <div className={classes.stockHeaderWrapper}>
          <h1 className={classes.stockHeader}>{STOCK_CALCULATIONS_ROUTE.TITLE}</h1>
        </div>
        <span className={classes.stockCalculationsButtonsWrapper}>
          <span>
            <button
              onClick={() => {
                navigate(`${CALCULATION_DETAILS_ROUTE.PATH}/0`);
              }}
            >
              Yeni Alım Hesabı Aç
            </button>
          </span>
          {/*           <span className={classes.stockCalculationsFiltersWrapper}>
            <input type="checkbox" checked={filter === "OPEN"} id="OPEN_FILTER" onChange={() => setFilter("OPEN")} />
            <label htmlFor="OPEN_FILTER">Açık Alımlar</label>

            <input type="checkbox" checked={filter === "CLOSED"} id="CLOSED_FILTER" onChange={() => setFilter("CLOSED")} />
            <label htmlFor="CLOSED_FILTER">Kapalı Alımlar</label>
          </span> */}
        </span>
        {calculationsLoading ? <ThreeCircles height="60" width="60" color="var(--primary-color)" /> : null}
        <div className={classes.calculationCardsWrapper}>
          {pooledFirms.map((item, index) => (
            <StockCalculationCard key={`${item._stockCalculationId}-${index}`} _stockCalculationId={item._stockCalculationId} note={item.note} firm={item.firm} />
          ))}
        </div>
      </div>
    </div>
  );
};
