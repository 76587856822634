import { useEffect, useRef, useState } from "react";
import { WorkOrder } from "../../Models/WorkOrder";
import moment from "moment";
import { ThreeCircles } from "react-loader-spinner";
import { Navbar } from "../../Components/Navbar/Navbar";
import { WORK_ORDER_ALL_ROUTE, WORK_ORDER_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import "./WorkOrdersAll.scss";
import { GlobalState } from "../../Redux/reducers";
import { useSelector } from "react-redux";
import { ExportBillPopUp } from "../../Components/PopUps/ExportBillPopUp/ExportBillPopUp";
import { useNavigate } from "react-router";
import useWorkOrder from "../../hooks/useWorkOrder";
import { API_LIMIT } from "../../Constants/ENUMS";

const date = new Date();

const numberWithCommas = (x: number) => {
  return (Math.round(x * 100) / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const WorkOrdersAll = () => {
  const [workOrders, setWorkOrders] = useState<{ customerName: string; customerAddress: string; workOrder: WorkOrder }[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [exportedContent, setExportedContent] = useState<{ children: React.ReactNode | undefined; workOrderDesc: string }>();

  const currencygeneral: number = useSelector((state: GlobalState) => state.currency.rate);

  const { workOrdersLoading, getAllWorkOrders } = useWorkOrder();
  const timerRef = useRef<number | undefined>(undefined);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm.length === 0) {
      getAllWorkOrders(pageCount, API_LIMIT, searchTerm)
        .then((result) => {
          if (pageCount === 1) {
            setWorkOrders(result);
          } else {
            setWorkOrders(workOrders.concat(result));
          }
        })
        .catch((err) => console.error(err));
    }
    if (searchTerm.length > 2) {
      if (timerRef.current) window.clearTimeout(timerRef.current);

      timerRef.current = window.setTimeout(() => {
        getAllWorkOrders(pageCount, API_LIMIT, searchTerm)
          .then((result) => {
            if (pageCount === 1) {
              setWorkOrders(result);
            } else {
              setWorkOrders(workOrders.concat(result));
            }
          })
          .catch((err) => console.error(err));
      }, 600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, searchTerm]);

  const exportComponent = (workOrder: WorkOrder, customerName: string, customerAddress: string) => {
    const currency = workOrder.currency === "EUR" ? 1 : currencygeneral;

    const sumValuesofOrder = workOrder.productsToBeSold
      .map((item) => {
        return item.product.pricePerAmount * item.toBeSoldAmount;
      })
      .reduce((a, b) => a + b, 0);

    const sumValuesofWork = workOrder.workToBeSold
      .map((item) => {
        return item.workPrice * item.workingHours;
      })
      .reduce((a, b) => a + b, 0);

    return (
      <div className="pdfWrapper">
        {workOrder !== undefined ? (
          <>
            <div className="pdfInfoWrapper">
              <div>
                <div className="pdfOrderCardcustomerDetailsHeaderWrapper">
                  <div className="orderCardcustomerDetailsHeader">{customerName}</div>
                  <div className="customerDetailsId">{customerAddress}</div>
                </div>

                <span className="pdfInfoLeftItemsWrapper">
                  {/* <div className="pdfInfoLeftItem">
                  <span className="pdfInfoLeftHeader">İŞ EMRİ TARİH </span>
                  <span className="pdfInfoLeftValue">{moment(currentDate).format("DD/MM/YYYY")}</span>
                </div> */}
                  <div className="pdfInfoLeftItem">
                    <span className="pdfInfoLeftHeader">İŞ EMRİ NO </span>
                    <span className="pdfInfoLeftValue">{workOrder._id}</span>
                  </div>
                  <div className="pdfInfoLeftItem">
                    <span className="pdfInfoLeftHeader">MAKİNE </span>
                    <span className="pdfInfoLeftValue">
                      {workOrder.Machine.brand} - {workOrder.Machine.modal}
                    </span>
                  </div>
                  <div className="pdfInfoLeftItem">
                    <span className="pdfInfoLeftHeader">SAAT </span>
                    <span className="pdfInfoLeftValue">{workOrder.hoursWorkedOnMachine}</span>
                  </div>
                  <div className="pdfStatusWrapper">{workOrder.status === "OFFER" ? "TEKLİF" : workOrder.status === "OPEN" ? "AÇIK" : "KAPALI"}</div>
                </span>
              </div>
              <div className="pdfInfoRight">
                <div className="pdfInfoRightWrapper">
                  {/*  <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightCodeHeader">KOD-P </span>
                  <span className="pdfInfoRightCodeValue">{orderCardContent.percentages.piecePercentage}</span>
                </span>
                <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightCodeHeader">KOD-İ </span>
                  <span className="pdfInfoRightCodeValue">{orderCardContent.percentages.workerPercentage}</span>
                </span>
                <span className="pdfInfoRightItem">
                  <span className="pdfInfoRightCodeHeader">KOD-K </span>
                  <span className="pdfInfoRightCodeValue">{orderCardContent.percentages.KDVPercentage}</span>
                </span> */}
                  <span className="pdfInfoRightItem">
                    <span className="pdfInfoRightHeader">İSKONTO TUTARI </span>
                    <span className="pdfInfoRightValue">
                      {numberWithCommas(
                        Math.round(
                          (sumValuesofOrder * (workOrder.percentages.piecePercentage / 100) + sumValuesofWork * (workOrder.percentages.workerPercentage / 100)) * currency * 100
                        ) / 100
                      )}
                    </span>
                  </span>
                  <span className="pdfInfoRightItem">
                    <span className="pdfInfoRightHeader">FATURA TARİH </span>
                    <span className="pdfInfoRightValue">{moment(workOrder.bill ? workOrder.bill.date : date).format("DD/MM/YYYY")}</span>
                  </span>
                  <span className="pdfInfoRightItem">
                    <span className="pdfInfoRightHeader">VADE </span>
                    <span className="pdfInfoRightValue">{workOrder.paymentExpiration}</span>
                  </span>
                  <span className="pdfInfoRightItem">
                    <span className="pdfInfoRightHeader">ÖDEME TARİHİ </span>
                    <span className="pdfInfoRightValue">
                      {moment(workOrder.bill ? workOrder.bill.date : date)
                        .add(workOrder.paymentExpiration, "days")
                        .format("DD/MM/YYYY")}
                    </span>
                  </span>
                  {/*   <span className="pdfInfoRightSum">
                  <span className="pdfInfoRightSumHeader">TOPLAM </span>
                  <span className="pdfInfoRightSumValue">
                    {" "}
                    {Math.round(
                      (sumValuesofOrder * ((100 - orderToChange.percentages.piecePercentage) / 100) +
                        sumValuesofWork * ((100 - orderToChange.percentages.workerPercentage) / 100)) *
                        ((orderToChange.percentages.KDVPercentage + 100) / 100) *
                        100
                    ) / 100}{" "}
                    {orderCardContent.currency === "EUR" ? <>€</> : <>₺</>}
                  </span>
                </span> */}
                </div>
              </div>
            </div>
            <div className="pdftableWrapper">
              <table>
                <thead>
                  <tr>
                    <th className="pdfthDescription">Açıklama</th>
                    <th className="pdfthAmount">Adet</th>
                    <th className="pdftableBorder">Birim Liste</th>
                    <th className="pdftableBorder">Birim Fiyat</th>
                    <th className="pdftableBorder">Toplam Fiyat</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {workOrder.productsToBeSold.map((productToSell, index) => {
                      return (
                        <tr key={index}>
                          <td className="pdftdDescription">
                            {productToSell.productCode} - {productToSell.productName} {productToSell.product.shelf ?? ""}
                          </td>
                          <td>{productToSell.toBeSoldAmount}</td>
                          <td>{numberWithCommas(Math.round(productToSell.product.pricePerAmount * currency * 100) / 100)}</td>
                          <td>
                            {numberWithCommas(Math.round(productToSell.product.pricePerAmount * ((100 - workOrder.percentages.piecePercentage) / 100) * currency * 100) / 100)}
                          </td>
                          <td>
                            {numberWithCommas(
                              Math.round(
                                productToSell.product.pricePerAmount * ((100 - workOrder.percentages.piecePercentage) / 100) * productToSell.toBeSoldAmount * currency * 100
                              ) / 100
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    {workOrder.workToBeSold.map((workToSell, index) => {
                      return (
                        <tr key={index}>
                          <td className="pdftdDescription">
                            {workToSell.workCode} - {workToSell.description}
                          </td>
                          <td>{workToSell.workingHours}</td>
                          <td>{numberWithCommas(Math.round(workToSell.workPrice * currency * 100) / 100)}</td>
                          <td>{numberWithCommas(Math.round(workToSell.workPrice * ((100 - workOrder.percentages.piecePercentage) / 100) * currency * 100) / 100)}</td>
                          <td>
                            {numberWithCommas(
                              Math.round(workToSell.workPrice * ((100 - workOrder.percentages.piecePercentage) / 100) * currency * workToSell.workingHours * 100) / 100
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                </tbody>
              </table>
            </div>

            <div className="pdfBottomInfoWrapper">
              <div style={{ display: "flex" }}>
                {/*               {orderCardContent.description}
                 */}
                <div>
                  <div className="pdfnoteInfo">
                    NOT TL OLARAK YAPILACAK ÖDEMELERDE AYNI GÜNÜN <br /> K.K.T.C MERKEZ BANKASI EFEKTİF SATIŞ KURU GEÇERLİDİR.
                  </div>
                  <div>
                    <span>
                      <span className="pdfbillIBAN">GARANTİ BANKASI TL </span>
                      <span>TR95 0006 2001 5890 0006 2994 61</span>
                    </span>
                  </div>
                  <div>
                    <span>
                      <span className="pdfbillIBAN">GARANTİ BANKASI EURO</span>
                      <span>TR55 0006 2001 5890 0009 0983 40</span>
                    </span>
                  </div>
                </div>
                <span className="pdfcalcWrapper">
                  <span>
                    <div>
                      <span className="pdfcalcHeader">TOPLAM </span>
                      <span>
                        {numberWithCommas(
                          Math.round(
                            (sumValuesofOrder * ((100 - workOrder.percentages.piecePercentage) / 100) + sumValuesofWork * ((100 - workOrder.percentages.workerPercentage) / 100)) *
                              currency *
                              100
                          ) / 100
                        )}
                        {workOrder.currency === "EUR" ? <>€</> : <>₺</>}
                      </span>
                    </div>
                    <div className="pdfcalculationEnd">
                      <span className="pdfcalcHeader">KDV %{workOrder.percentages.KDVPercentage}</span>
                      <span>
                        {numberWithCommas(
                          Math.round(
                            (sumValuesofOrder * ((100 - workOrder.percentages.piecePercentage) / 100) + sumValuesofWork * ((100 - workOrder.percentages.workerPercentage) / 100)) *
                              (workOrder.percentages.KDVPercentage / 100) *
                              currency *
                              100
                          ) / 100
                        )}
                        {workOrder.currency === "EUR" ? <>€</> : <>₺</>}
                      </span>
                    </div>
                    <div style={{ fontWeight: 600 }}>
                      <span className="pdfcalcHeader">TOPLAM </span>
                      <span>
                        {numberWithCommas(
                          Math.round(
                            (sumValuesofOrder * ((100 - workOrder.percentages.piecePercentage) / 100) + sumValuesofWork * ((100 - workOrder.percentages.workerPercentage) / 100)) *
                              ((workOrder.percentages.KDVPercentage + 100) / 100) *
                              currency *
                              100
                          ) / 100
                        )}
                        {workOrder.currency === "EUR" ? <>€</> : <>₺</>}
                      </span>
                    </div>
                  </span>
                </span>
              </div>

              <span className="pdfsignatureWrapper">
                <span>
                  <div>Teslim eden</div>
                  <div>Serdar Özkan</div>
                </span>
                <span>
                  Teslim Alan
                  {/*                 <div className="pdfsignature" />
                   */}{" "}
                </span>
              </span>
            </div>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      <Navbar />

      <div className="workOrderAllWrapper">
        <div className="workOrderAllHeaderWrapper">
          <h1 className="workOrderAllHeader">{WORK_ORDER_ALL_ROUTE.TITLE}</h1>
        </div>
        <div className="searchLoaderWrapper">
          <div className="group">
            <svg viewBox="0 0 24 24" fill="white" aria-hidden="true" className="searchIcon">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
            <input
              onChange={(e) => {
                setSearchTerm(e.target.value.toUpperCase());
                setPageCount(1);
              }}
              className="input"
              type="text"
              placeholder="Ara..."
            />
          </div>
          {workOrdersLoading ? (
            <div className="LoaderWrapperWorkOrder">
              <ThreeCircles height="60" width="60" color="var(--primary-color)" />
            </div>
          ) : null}
        </div>

        <div className="workOrderAllTableWrapper">
          {exportedContent ? (
            <ExportBillPopUp fileName={exportedContent.workOrderDesc} close={() => setExportedContent(undefined)}>
              {exportedContent.children}
            </ExportBillPopUp>
          ) : null}
          <table className="fl-table">
            <thead>
              <tr>
                <th>Durum</th>
                <th>Müşteri</th>
                <th>Numara</th>
                <th>Makine</th>
                <th>Açıklama</th>
                <th>Fatura Tarih</th>
                <th>Toplam Tutar</th>
                <th>PDF</th>
                <th>Firmaya Git</th>
              </tr>
            </thead>
            <tbody>
              {workOrders.map((workOrder, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 20 20"
                        fill={workOrder.workOrder.status === "OFFER" ? "yellow" : workOrder.workOrder.status === "OPEN" ? "rgb(187, 1, 1)" : "gray"}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18Z" />
                        <path d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z" />
                      </svg>
                      {/*  {workOrder.status === "OFFER" ? "Teklif" : workOrder.status === "OPEN" ? "Açık" : "Kapalı"} */}
                    </td>
                    <td className="workorderAllTableDesc">{workOrder.customerName}</td>
                    <td>{workOrder.workOrder._id}</td>
                    <td>{workOrder.workOrder.Machine.modal}</td>
                    <td className="workorderAllTableDesc">{workOrder.workOrder.description}</td>
                    <td>{workOrder.workOrder.bill ? moment(workOrder.workOrder.bill.date).format("DD/MM/YYYY") : ""}</td>
                    <td>{numberWithCommas(workOrder.workOrder.totalPrice)}</td>
                    <td>
                      <button
                        className="exportBtn"
                        onClick={() => {
                          setExportedContent({
                            children: exportComponent(workOrder.workOrder, workOrder.customerName, workOrder.customerAddress),
                            workOrderDesc: workOrder.workOrder.description,
                          });
                        }}
                      >
                        PDF
                      </button>
                    </td>
                    <td className="workorderAllTableCustomer">
                      <button
                        style={{ marginRight: "1rem" }}
                        onClick={() =>
                          navigate(
                            `${WORK_ORDER_ROUTE.PATH}/${workOrder.workOrder._customerId}/WorkOrders/${workOrder.workOrder.status === "CLOSED" ? 0 : workOrder.workOrder._id}`
                          )
                        }
                        className="cssbuttons-io-button"
                      >
                        Firma
                        <div className="icon">
                          <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path>
                          </svg>
                        </div>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <button onClick={() => setPageCount(pageCount + 1)} className="loadMoreButton">
            Daha fazla yükle
          </button>
        </div>
      </div>
    </div>
  );
};
