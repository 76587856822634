import { Reducer } from "redux";

const initArr: string[] = [
  "boughtPriceAmountTimesAmount",
  "customsTimesAmount",
  "KKTC_KDVTimesAmount",
  "total",
  "workDone",
  "productsSold",
  "KDV",
  "totalSell",
  "totalDiff",
  "boughtPriceAmount",
  "customs",
  "KKTC_KDV",
  "totalProdEntry",
];

interface IAction {
  type: string;
  payload: string[];
}

const DashboardSelectionsReducer: Reducer<string[], IAction> = (state: string[] = initArr, action: IAction): string[] => {
  switch (action.type) {
    case "CHANGE_SELECTION":
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default DashboardSelectionsReducer;
