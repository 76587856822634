import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { StokCalculation } from "../../Models/StockCalculation";
import { CalculationGroup } from "../../Models/CalculationGroup";
import useProduct from "../../hooks/useProduct";
import { API_LIMIT } from "../../Constants/ENUMS";
import { Product } from "../../Models/Product";
import ReactSelect, { SingleValue } from "react-select";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import classes from "./CalculationDetailsPage.module.scss";
import { CALCULATION_DETAILS_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import { useNavigate, useParams } from "react-router-dom";
import { OtherExpenses } from "../../Models/OtherExpenses";
import useCalculation from "../../hooks/useCalculation";
import { ThreeCircles } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/reducers";
import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import moment from "moment";
import { NumberInput } from "../../Components/NumberInput/NumberInput";

registerLocale("tr", tr);

type RSelectOption = {
  value: Product;
  label: string;
};

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

//filter width hallet

export const CalculationDetailsPage = () => {
  const [calculationGroup, setCalculationGroup] = useState<CalculationGroup>({
    _stockCalculationId: 0,
    currencyGeneral: 1,
    note: "",
    firms: [{ firmName: "", billNo: "", date: new Date(), currency: 0, billPrice: 0 }],
    stopajnonPercentageSum: 0,
    isStopajPercentaged: false,
    KDVnonPercentageSum: 0,
    isKDVPercentaged: false,
    customsNonPercentageSum: 0,
    isCustomsPercentaged: false,
    oilsNonPercentageSum: 0,
    isOilsPercentaged: false,
    cifTotal: {
      navlun: 0,
      ordino: 0,
    },
    stockCalculations: [],
    otherExpenses: { extraWork: 0, taxi: 0, stampExpenses: 0, expenses: 0, difference: 0, essential: 0 },
  });

  const [manuelCalculation, setManuelCalculation] = useState<{
    otherExpenses: OtherExpenses;
    trBuy: number;
    trDocument: number;
    oils: number;
    kdv: number;
    stopaj: number;
    navlun: number;
    ordino: number;
    customs: number;
    other1: number;
    other2: number;
    other3: number;
  }>({
    otherExpenses: { extraWork: 0, taxi: 0, stampExpenses: 0, expenses: 0, difference: 0, essential: 0 },
    trBuy: 0,
    trDocument: 0,
    oils: 0,
    kdv: 0,
    stopaj: 0,
    navlun: 0,
    ordino: 0,
    customs: 0,
    other1: 0,
    other2: 0,
    other3: 0,
  });
  const { currencygeneral } = useSelector((state: GlobalState) => ({ currencygeneral: state.currency }));

  const [products, setProducts] = useState<Product[]>([]);
  const [date, setDate] = useState<Date>(new Date());

  const navigate = useNavigate();

  const { productsLoading, getAllProducts } = useProduct();

  const { calcId } = useParams<{ calcId: string }>();

  const { calculationsLoading, getCalculationDetails, updateCalculation, postCalculation } = useCalculation();

  useEffect(() => {
    if (calcId && parseInt(calcId) !== 0) {
      getCalculationDetails(parseInt(calcId)).then((calcDetails) => {
        setCalculationGroup(calcDetails);
        if (calcDetails.stockCalculations[0]) {
          setDate(calcDetails.stockCalculations[0].date);
        }
      });
    } else if (currencygeneral) {
      setCalculationGroup({
        ...calculationGroup,
        currencyGeneral: currencygeneral.rate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencygeneral]);

  //Use effects for resetting when calc type is changed for each value

  //customs
  const changeCustoms = () => {
    const newCalc = {
      ...calculationGroup,
      isCustomsPercentaged: !calculationGroup.isCustomsPercentaged,
      stockCalculations: calculationGroup.stockCalculations.map((st) => {
        return {
          ...st,
          preCustoms: 0,
          preCustomsPercentage: 0,
          KKTC_KDV: calculationGroup.isKDVPercentaged ? (st.cif + st.boughtPriceAmount + st.oils) * (st.KKTC_KDV_Percentage / 100) : st.KKTC_KDV,
        };
      }),
    };

    reCalculateTotalsWithNewCif_BoughtPrices(newCalc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //oils
  const changeOils = () => {
    const newCalc = {
      ...calculationGroup,
      isOilsPercentaged: !calculationGroup.isOilsPercentaged,
      stockCalculations: calculationGroup.stockCalculations.map((st) => {
        return {
          ...st,
          oils: 0,
          oilsPercentage: 0,
          KKTC_KDV: calculationGroup.isKDVPercentaged ? (st.cif + st.boughtPriceAmount + st.preCustoms) * (st.KKTC_KDV_Percentage / 100) : st.KKTC_KDV,
        };
      }),
    };
    reCalculateTotalsWithNewCif_BoughtPrices(newCalc);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //stopaj
  const changeStopaj = () => {
    setCalculationGroup({
      ...calculationGroup,
      stopajnonPercentageSum: 0,
      isStopajPercentaged: !calculationGroup.isStopajPercentaged,
      stockCalculations: calculationGroup.stockCalculations.map((st) => {
        return {
          ...st,
          stopaj: 0,
          stopajPercentage: 0,
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //KDV
  const changeKDV = () => {
    setCalculationGroup({
      ...calculationGroup,
      KDVnonPercentageSum: 0,
      isKDVPercentaged: !calculationGroup.isKDVPercentaged,
      stockCalculations: calculationGroup.stockCalculations.map((st) => {
        return {
          ...st,
          KKTC_KDV_Percentage: 0,
          KKTC_KDV: 0,
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const totalBoughtPriceAmount = useMemo(() => {
    return calculationGroup.stockCalculations.map((st) => st.boughtAmount * st.boughtPriceAmount).reduce((a, b) => a + b, 0);
  }, [calculationGroup.stockCalculations]);

  const handleSelectedOptionChange = (newSelectedOption: SingleValue<RSelectOption>) => {
    const newCalculation: StokCalculation = {
      _calcId: "",
      cif: 0,
      oils: 0,
      otherExpensesPercentageShared: 0,
      oilsPercentage: 0,
      stopajPercentage: 0,
      KKTC_KDV_Percentage: 0,
      preCustomsPercentage: 0,
      date: date,

      currency: calculationGroup.currencyGeneral,
      boughtPriceAmount: 0,
      pricePerAmount: 0,
      boughtAmount: 1,
      preCustoms: 0,
      KKTC_KDV: 0,
      salesListPercentage: 60,
      stopaj: 0,

      _productId: newSelectedOption?.value._productId ?? 0,
      productName: newSelectedOption?.value.name ?? "",
      productCode: newSelectedOption?.value.code ?? "",
      productBrand: newSelectedOption?.value.brand ?? "",
      productNote: newSelectedOption?.value.note ?? "",

      status: "OPEN",
      firmName: "",
    };

    setCalculationGroup({
      ...calculationGroup,
      stockCalculations: [...calculationGroup.stockCalculations, newCalculation],
    });
  };

  const timerRef = useRef<number | undefined>(undefined);

  const searchProducts = useCallback(
    (search: string) => {
      if (search.length > 2) {
        if (timerRef.current) window.clearTimeout(timerRef.current);

        timerRef.current = window.setTimeout(() => {
          getAllProducts(1, API_LIMIT, search)
            .then((result) => {
              setProducts(result);
            })
            .catch((err) => console.error(err));
        }, 600);
      }
    },
    [getAllProducts]
  );

  const reCalculateTotalsWithNewCif_BoughtPrices = useCallback((newCalcGroup: CalculationGroup) => {
    setCalculationGroup({
      ...newCalcGroup,

      oilsNonPercentageSum: newCalcGroup.isOilsPercentaged
        ? newCalcGroup.stockCalculations.map((st) => st.oils * st.boughtAmount).reduce((a, b) => a + b, 0)
        : newCalcGroup.oilsNonPercentageSum,
      customsNonPercentageSum: newCalcGroup.isCustomsPercentaged
        ? newCalcGroup.stockCalculations.map((st) => st.preCustoms * st.boughtAmount).reduce((a, b) => a + b, 0)
        : newCalcGroup.customsNonPercentageSum,
      stopajnonPercentageSum: newCalcGroup.isStopajPercentaged
        ? newCalcGroup.stockCalculations.map((st) => st.stopaj * st.boughtAmount).reduce((a, b) => a + b, 0)
        : newCalcGroup.stopajnonPercentageSum,
      KDVnonPercentageSum: newCalcGroup.isKDVPercentaged
        ? newCalcGroup.stockCalculations.map((st) => st.KKTC_KDV * st.boughtAmount).reduce((a, b) => a + b, 0)
        : newCalcGroup.KDVnonPercentageSum,
    });
  }, []);

  const reCalculatePercentagesWithNewCif_BoughtPrices = (newCalcGroup: CalculationGroup) => {
    const totalPrice = newCalcGroup.stockCalculations.map((st) => st.boughtAmount * st.boughtPriceAmount).reduce((a, b) => a + b, 0);

    const anotherCalcGroup = {
      ...newCalcGroup,

      stockCalculations: newCalcGroup.stockCalculations.map((calculation) => {
        const oils = newCalcGroup.isOilsPercentaged
          ? ((calculation.cif + calculation.boughtPriceAmount) * calculation.oilsPercentage) / 100
          : (newCalcGroup.oilsNonPercentageSum * calculation.boughtPriceAmount) / totalPrice;
        const precustoms = newCalcGroup.isCustomsPercentaged
          ? ((calculation.cif + calculation.boughtPriceAmount) * calculation.preCustomsPercentage) / 100
          : (newCalcGroup.customsNonPercentageSum * calculation.boughtPriceAmount) / totalPrice;
        return {
          ...calculation,
          //IF İTS PERCENTAGED THE PERCENTAGE VALUE DOESNT CHANGE, THE NUMERİC VALUE CHANGES.
          oils: oils,

          preCustoms: precustoms,

          stopaj: newCalcGroup.isStopajPercentaged
            ? ((calculation.cif + calculation.boughtPriceAmount) * calculation.stopajPercentage) / 100
            : (newCalcGroup.stopajnonPercentageSum * calculation.boughtPriceAmount) / totalPrice,

          KKTC_KDV: newCalcGroup.isKDVPercentaged
            ? (calculation.cif + calculation.boughtPriceAmount + oils + precustoms) * (calculation.KKTC_KDV_Percentage / 100)
            : (newCalcGroup.KDVnonPercentageSum * calculation.boughtPriceAmount) / totalPrice,

          otherExpensesPercentageShared: (Object.values(newCalcGroup.otherExpenses).reduce((a, b) => a + b, 0) * calculation.boughtPriceAmount) / totalPrice,
        };
      }),
    };
    reCalculateTotalsWithNewCif_BoughtPrices(anotherCalcGroup);
  };

  return (
    <div className={classes.calculationDetailsPageWrapper}>
      {calculationsLoading ? (
        <div className={classes.LoaderWrapper}>
          <ThreeCircles height="80" width="80" color="var(--primary-color)" />
        </div>
      ) : null}
      <div className={classes.backIcon} onClick={() => navigate(-1)}>
        <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 11V8L8 12L12 16V13H16V11H12Z" fill="var(--primary-color)" />
        </svg>
      </div>
      <div className={classes.header}>{CALCULATION_DETAILS_ROUTE.TITLE}</div>
      <div className={classes.topPartWrapper}>
        <div className={classes.firmsWrapper}>
          <div className={classes.firmsWrapperHeader}>
            <h3>Firma</h3>
            <h3>Fatura No</h3>
            <h3>Tarih</h3>
            <h3>Döviz</h3>
            <h3>Tutar</h3>
          </div>
          <div className={classes.firmsWrapperBody}>
            {calculationGroup.firms.map((firm, i) => {
              return (
                <div className={classes.firmsWrapperBodyItem}>
                  <div>
                    <input
                      value={firm.firmName}
                      onChange={(e) => {
                        const tempFirms = [...calculationGroup.firms];
                        tempFirms[i].firmName = e.target.value;

                        setCalculationGroup({
                          ...calculationGroup,
                          firms: tempFirms,
                          stockCalculations: calculationGroup.stockCalculations.map((calc) => {
                            if (calc.firmName === firm.firmName) {
                              return {
                                ...calc,
                                firmName: e.target.value,
                              };
                            }
                            return calc;
                          }),
                        });
                      }}
                    />
                  </div>
                  <div>
                    {" "}
                    <input
                      value={firm.billNo}
                      onChange={(e) => {
                        const tempFirms = [...calculationGroup.firms];
                        tempFirms[i].billNo = e.target.value;

                        setCalculationGroup({
                          ...calculationGroup,
                          firms: tempFirms,
                          stockCalculations: calculationGroup.stockCalculations.map((calc) => {
                            if (calc.firmName === firm.firmName) {
                              return {
                                ...calc,
                                firmName: e.target.value,
                              };
                            }
                            return calc;
                          }),
                        });
                      }}
                    />
                  </div>
                  <div style={{ zIndex: 555 }}>
                    <DatePicker
                      className={classes.datePickerCalc}
                      value={moment(firm.date).format("DD/MM/YYYY")}
                      placeholderText="Tarih Giriniz..."
                      locale={tr}
                      onChange={(dateInput) => {
                        if (dateInput) {
                          const tempFirms = [...calculationGroup.firms];
                          tempFirms[i].date = dateInput;

                          setCalculationGroup({
                            ...calculationGroup,
                            firms: tempFirms,
                            stockCalculations: calculationGroup.stockCalculations.map((calc) => {
                              if (calc.firmName === firm.firmName) {
                                return {
                                  ...calc,
                                  date: dateInput,
                                };
                              }
                              return calc;
                            }),
                          });
                        }
                      }}
                    />
                  </div>
                  <div>
                    {" "}
                    <input
                      value={firm.currency}
                      type="number"
                      step="0.1"
                      onChange={(e) => {
                        const newCurrency = parseFloat(e.target.value);
                        const tempFirms = [...calculationGroup.firms];
                        tempFirms[i].currency = newCurrency;

                        setCalculationGroup({
                          ...calculationGroup,
                          firms: tempFirms,
                          stockCalculations: calculationGroup.stockCalculations.map((calc) => {
                            if (calc.firmName === firm.firmName) {
                              return {
                                ...calc,
                                currency: newCurrency,
                              };
                            }
                            return calc;
                          }),
                        });
                      }}
                    />
                  </div>
                  <div>
                    {" "}
                    <input
                      value={firm.billPrice}
                      type="number"
                      step="0.1"
                      onChange={(e) => {
                        const tempFirms = [...calculationGroup.firms];
                        tempFirms[i].billPrice = parseFloat(e.target.value);

                        setCalculationGroup({
                          ...calculationGroup,
                          firms: tempFirms,
                          stockCalculations: calculationGroup.stockCalculations.map((calc) => {
                            if (calc.firmName === firm.firmName) {
                              return {
                                ...calc,
                                firmName: e.target.value,
                              };
                            }
                            return calc;
                          }),
                        });
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div>
            <button
              onClick={() => {
                setCalculationGroup({ ...calculationGroup, firms: [...calculationGroup.firms, { firmName: "", billNo: "", date: new Date(), currency: 0, billPrice: 0 }] });
              }}
            >
              Firma Ekle
            </button>
          </div>
        </div>
        <div className={classes.navlunOrdinoWrapper}>
          <div className={classes.HeaderCifTotal}>Cif Toplam</div>
          <div className={classes.navlunOrdinoItem}>
            <h4>Navlun</h4>
            <NumberInput
              value={calculationGroup.cifTotal.navlun}
              onChange={(value) => {
                const newCalcGroup = {
                  ...calculationGroup,
                  cifTotal: { ...calculationGroup.cifTotal, navlun: value },
                  stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                    return {
                      ...calculation,
                      cif: (value + calculationGroup.cifTotal.ordino) * (calculation.boughtPriceAmount / totalBoughtPriceAmount),
                    };
                  }),
                };
                reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
              }}
              placeholder="Navlun Giriniz"
              className={classes.calculationProductInput}
              disabled={false}
            />
          </div>
          <div className={classes.navlunOrdinoItem}>
            <h4>Ordino</h4>
            <NumberInput
              value={calculationGroup.cifTotal.ordino}
              onChange={(value) => {
                const newCalcGroup = {
                  ...calculationGroup,
                  cifTotal: { ...calculationGroup.cifTotal, ordino: value },
                  stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                    return {
                      ...calculation,
                      cif: (value + calculationGroup.cifTotal.navlun) * (calculation.boughtPriceAmount / totalBoughtPriceAmount),
                    };
                  }),
                };
                reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
              }}
              placeholder="Ordino Giriniz"
              className={classes.calculationProductInput}
            />
          </div>

          <div className={classes.cifTotal}>Toplam: {numberWithCommas(calculationGroup.cifTotal.navlun + calculationGroup.cifTotal.ordino)}₺</div>
        </div>

        <div className={classes.navlunOrdinoWrapper}>
          <div className={classes.HeaderCifTotal}>Vergi Ödemeleri</div>
          <div className={classes.navlunOrdinoItem}>
            <span className={classes.orderPartFiltersWrapper}>
              <input
                type="checkbox"
                checked={!calculationGroup.isCustomsPercentaged}
                id="CUSTOMS_PERCENTAGE"
                onChange={() => {
                  changeCustoms();
                }}
              />
              <label htmlFor="CUSTOMS_PERCENTAGE">Gümrük</label>
            </span>
            <NumberInput
              value={calculationGroup.customsNonPercentageSum}
              onChange={(value) => {
                setCalculationGroup({
                  ...calculationGroup,
                  customsNonPercentageSum: value,
                  stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                    const customs = value * (calculation.boughtPriceAmount / totalBoughtPriceAmount);
                    return {
                      ...calculation,
                      preCustoms: customs,
                      KKTC_KDV: calculationGroup.isKDVPercentaged
                        ? (calculation.cif + calculation.boughtPriceAmount + calculation.oils + customs) * (calculation.KKTC_KDV_Percentage / 100)
                        : calculation.KKTC_KDV,
                    };
                  }),
                });
              }}
              placeholder="Değer Giriniz"
              disabled={calculationGroup.isCustomsPercentaged}
              className={classes.calculationProductInput}
            />
          </div>
          <div className={classes.navlunOrdinoItem}>
            <span className={classes.orderPartFiltersWrapper}>
              <input type="checkbox" checked={!calculationGroup.isOilsPercentaged} id="OILS_PERCENTAGE" onChange={() => changeOils()} />
              <label htmlFor="OILS_PERCENTAGE">Yağlar</label>
            </span>
            <NumberInput
              placeholder="Kur Giriniz"
              disabled={calculationGroup.isOilsPercentaged}
              value={calculationGroup.oilsNonPercentageSum}
              onChange={(value) => {
                setCalculationGroup({
                  ...calculationGroup,
                  oilsNonPercentageSum: value,
                  stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                    const oils = value * (calculation.boughtPriceAmount / totalBoughtPriceAmount);
                    return {
                      ...calculation,
                      oils: oils,
                      KKTC_KDV: calculationGroup.isKDVPercentaged
                        ? (calculation.cif + calculation.boughtPriceAmount + calculation.preCustoms + oils) * (calculation.KKTC_KDV_Percentage / 100)
                        : calculation.KKTC_KDV,
                    };
                  }),
                });
              }}
            />
          </div>

          <div className={classes.navlunOrdinoItem}>
            <span className={classes.orderPartFiltersWrapper}>
              <input type="checkbox" checked={!calculationGroup.isStopajPercentaged} id="STOPAJ_PERCENTAGE" onChange={() => changeStopaj()} />
              <label htmlFor="STOPAJ_PERCENTAGE">Stopaj</label>
            </span>

            <NumberInput
              placeholder="Değer Giriniz"
              disabled={calculationGroup.isStopajPercentaged}
              value={calculationGroup.stopajnonPercentageSum}
              onChange={(value) => {
                setCalculationGroup({
                  ...calculationGroup,
                  stopajnonPercentageSum: value,
                  stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                    return { ...calculation, stopaj: value * (calculation.boughtPriceAmount / totalBoughtPriceAmount) };
                  }),
                });
              }}
            />
          </div>

          <div className={classes.navlunOrdinoItem}>
            <span className={classes.orderPartFiltersWrapper}>
              <input type="checkbox" checked={!calculationGroup.isKDVPercentaged} id="KDV_PERCENTAGE" onChange={() => changeKDV()} />
              <label htmlFor="KDV_PERCENTAGE">KDV</label>
            </span>

            <NumberInput
              placeholder="KDV Giriniz"
              value={calculationGroup.KDVnonPercentageSum}
              disabled={calculationGroup.isKDVPercentaged}
              onChange={(value) => {
                setCalculationGroup({
                  ...calculationGroup,
                  KDVnonPercentageSum: value,
                  stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                    return { ...calculation, KKTC_KDV: value * (calculation.boughtPriceAmount / totalBoughtPriceAmount) };
                  }),
                });
              }}
            />
          </div>
        </div>
        <div className={classes.otherExpensesTop}>
          <span className={classes.accordionExpenses}>
            <Accordion>
              <AccordionSummary
                expandIcon={
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 16.172L18.364 10.808L19.778 12.222L12 20L4.222 12.222L5.636 10.808L11 16.172V4H13V16.172Z" fill="var(--secondary-color)" />
                  </svg>
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {" "}
                <div className={classes.accordionExpensesHeader}>
                  Diğer Giderler:{" "}
                  {numberWithCommas(
                    Math.round(
                      calculationGroup.stockCalculations
                        .map((item) => {
                          return (
                            (item.KKTC_KDV + item.boughtPriceAmount + item.cif + item.oils + item.otherExpensesPercentageShared + item.preCustoms + item.stopaj) * item.boughtAmount
                          );
                        })
                        .reduce((a, b) => a + b, 0) * 100
                    ) / 100
                  )}{" "}
                  ₺
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {" "}
                <div style={{ flexDirection: "column" }} className={classes.otherExpensesWrapper}>
                  <div className={classes.otherExpensesTotal}>
                    Toplam:{" "}
                    {numberWithCommas(
                      Math.round(
                        calculationGroup.stockCalculations
                          .map((item) => {
                            return (item.KKTC_KDV + item.boughtPriceAmount + item.cif + item.oils + item.preCustoms + item.stopaj) * item.boughtAmount;
                          })
                          .reduce((a, b) => a + b, 0) * 100
                      ) / 100
                    )}{" "}
                    ₺
                  </div>
                  <div className={classes.otherExpensesValueOther}>
                    <div>Ek Mesai</div>
                    <NumberInput
                      className={classes.otherExpensesInput}
                      placeholder="Değer Giriniz"
                      value={calculationGroup.otherExpenses.extraWork}
                      onChange={(value) => {
                        const newCalcGroup = {
                          ...calculationGroup,
                          otherExpenses: { ...calculationGroup.otherExpenses, extraWork: value },
                        };
                        reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                      }}
                    />
                  </div>
                  <div className={classes.otherExpensesValueOther}>
                    {" "}
                    <div>Taxi</div>
                    <NumberInput
                      value={calculationGroup.otherExpenses.taxi}
                      onChange={(value) => {
                        const newCalcGroup = {
                          ...calculationGroup,
                          otherExpenses: { ...calculationGroup.otherExpenses, taxi: value },
                        };
                        reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                      }}
                    />
                  </div>
                  <div className={classes.otherExpensesValueOther}>
                    <div>Pul Gideri</div>
                    <NumberInput
                      value={calculationGroup.otherExpenses.stampExpenses}
                      onChange={(value) => {
                        const newCalcGroup = {
                          ...calculationGroup,
                          otherExpenses: { ...calculationGroup.otherExpenses, stampExpenses: value },
                        };
                        reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                      }}
                      className={classes.calculationProductInput}
                    />
                  </div>
                  <div className={classes.otherExpensesValueOther}>
                    <div>Masraflar</div>
                    <NumberInput
                      value={calculationGroup.otherExpenses.expenses}
                      onChange={(value) => {
                        const newCalcGroup = {
                          ...calculationGroup,
                          otherExpenses: { ...calculationGroup.otherExpenses, expenses: value },
                        };
                        reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                      }}
                      className={classes.calculationProductInput}
                    />
                  </div>
                  <div className={classes.otherExpensesValueOther}>
                    <div>Essential</div>
                    <NumberInput
                      value={calculationGroup.otherExpenses.essential}
                      onChange={(value) => {
                        const newCalcGroup = {
                          ...calculationGroup,
                          otherExpenses: { ...calculationGroup.otherExpenses, essential: value },
                        };
                        reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                      }}
                      className={classes.calculationProductInput}
                    />
                  </div>
                  <div className={classes.otherExpensesValueOther}>
                    <div>Fark</div>
                    <NumberInput
                      value={calculationGroup.otherExpenses.difference}
                      onChange={(value) => {
                        const newCalcGroup = {
                          ...calculationGroup,
                          otherExpenses: { ...calculationGroup.otherExpenses, difference: value },
                        };
                        reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                      }}
                    />
                  </div>
                  <div className={classes.otherExpensesTotal}>
                    Toplam:{" "}
                    {numberWithCommas(
                      Math.round(
                        calculationGroup.stockCalculations
                          .map((item) => {
                            return (
                              (item.KKTC_KDV + item.boughtPriceAmount + item.cif + item.oils + item.otherExpensesPercentageShared + item.preCustoms + item.stopaj) *
                              item.boughtAmount
                            );
                          })
                          .reduce((a, b) => a + b, 0) * 100
                      ) / 100
                    )}{" "}
                    ₺
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </span>
        </div>
      </div>

      <div className={classes.calcsTopWrapper}>
        <div className={classes.rSelectWrapper}>
          <h3>Ürün Ekleme</h3>
          <ReactSelect
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "var(--secondary-color)",
                primary: "var(--primary-color)",
              },
            })}
            className={classes.rSelect}
            options={products.map((product) => {
              return {
                value: product,
                label: `${product.code.toUpperCase()} : ${product.name.toUpperCase()} : ${product.brand ? product.brand.toUpperCase() : ""} ${
                  product.note ? " :" + product.note.toUpperCase() : ""
                } `,
              };
            })}
            noOptionsMessage={() => {
              if (productsLoading) {
                return "Yükleniyor...";
              }
              return "Ürün bulunamadı";
            }}
            placeholder="Arama yapabilmek için en az 3 karakter giriniz"
            onInputChange={(e) => searchProducts(e)}
            onChange={handleSelectedOptionChange}
          />
        </div>
        <div className={classes.accordionExpensesCalculationWrapper}>
          <div className={classes.accordionExpenses}>
            <Accordion>
              <AccordionSummary
                expandIcon={
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13 16.172L18.364 10.808L19.778 12.222L12 20L4.222 12.222L5.636 10.808L11 16.172V4H13V16.172Z" fill="var(--secondary-color)" />
                  </svg>
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {" "}
                <div className={classes.accordionExpensesHeader}>
                  El İle Giriş (Doğrulama):{" "}
                  {numberWithCommas(
                    Math.round(
                      (manuelCalculation.navlun +
                        manuelCalculation.ordino +
                        manuelCalculation.oils +
                        manuelCalculation.kdv +
                        manuelCalculation.customs +
                        manuelCalculation.stopaj +
                        manuelCalculation.trBuy +
                        manuelCalculation.trDocument) *
                        100
                    ) / 100
                  )}
                  ₺
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {" "}
                <div className={classes.manuelCalcGeneral}>
                  <div className={classes.manuelCalcWrapper}>
                    <div className={classes.otherExpensesWrapper}>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Tr Alım</div>
                        <div>{totalBoughtPriceAmount} ₺</div>

                        <input
                          step=".01"
                          className={classes.otherExpensesInput}
                          type="number"
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.trBuy}
                          onChange={(e) => {
                            const inputValue = isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value);
                            setManuelCalculation({ ...manuelCalculation, trBuy: inputValue });
                          }}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        {" "}
                        <div className={classes.otherExpensesHeader}>Navlun</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.cifTotal.navlun * 100) / 100)} ₺</div>
                        <NumberInput
                          value={calculationGroup.cifTotal.navlun}
                          onChange={(value) => {
                            const newCalcGroup = {
                              ...calculationGroup,
                              cifTotal: { ...calculationGroup.cifTotal, navlun: value },
                              stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                                return {
                                  ...calculation,
                                  cif: (value + calculationGroup.cifTotal.ordino) * (calculation.boughtPriceAmount / totalBoughtPriceAmount),
                                };
                              }),
                            };
                            reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                          }}
                          placeholder="Navlun Giriniz"
                          className={classes.calculationProductInput}
                          disabled={false}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Ordino</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.cifTotal.ordino * 100) / 100)} ₺</div>
                        <NumberInput
                          value={calculationGroup.cifTotal.ordino}
                          onChange={(value) => {
                            const newCalcGroup = {
                              ...calculationGroup,
                              cifTotal: { ...calculationGroup.cifTotal, ordino: value },
                              stockCalculations: calculationGroup.stockCalculations.map((calculation) => {
                                return {
                                  ...calculation,
                                  cif: (value + calculationGroup.cifTotal.navlun) * (calculation.boughtPriceAmount / totalBoughtPriceAmount),
                                };
                              }),
                            };
                            reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                          }}
                          placeholder="Ordino Giriniz"
                          className={classes.calculationProductInput}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Gümrük</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.customsNonPercentageSum * 100) / 100)} ₺</div>

                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.customs}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, customs: value });
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.otherExpensesWrapper}>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Yağlar</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.oilsNonPercentageSum * 100) / 100)} ₺</div>
                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.oils}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, oils: value });
                          }}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Stopaj</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.stopajnonPercentageSum * 100) / 100)} ₺</div>
                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.stopaj}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, stopaj: value });
                          }}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>KDV</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.KDVnonPercentageSum * 100) / 100)} ₺</div>

                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.kdv}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, kdv: value });
                          }}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Ek Mesai</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.otherExpenses.extraWork * 100) / 100)} ₺</div>
                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.otherExpenses.extraWork}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, otherExpenses: { ...manuelCalculation.otherExpenses, extraWork: value } });
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.otherExpensesWrapper}>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Taxi</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.otherExpenses.taxi * 100) / 100)} ₺</div>

                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.otherExpenses.taxi}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, otherExpenses: { ...manuelCalculation.otherExpenses, taxi: value } });
                          }}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Pul Gideri</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.otherExpenses.stampExpenses * 100) / 100)} ₺</div>

                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.otherExpenses.stampExpenses}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, otherExpenses: { ...manuelCalculation.otherExpenses, stampExpenses: value } });
                          }}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Masraflar</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.otherExpenses.expenses * 100) / 100)} ₺</div>

                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.otherExpenses.expenses}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, otherExpenses: { ...manuelCalculation.otherExpenses, expenses: value } });
                          }}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Essential</div>
                        <div>{numberWithCommas(Math.round(calculationGroup.otherExpenses.essential * 100) / 100)} ₺</div>

                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.otherExpenses.essential}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, otherExpenses: { ...manuelCalculation.otherExpenses, essential: value } });
                          }}
                        />
                      </div>
                    </div>
                    <div className={classes.otherExpensesWrapper}>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>TR İhracat</div>
                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.trDocument}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, trDocument: value });
                          }}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Diğer</div>
                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.other1}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, other1: value });
                          }}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Diğer</div>
                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.other2}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, other2: value });
                          }}
                        />
                      </div>
                      <div className={classes.otherExpensesValueManuel}>
                        <div className={classes.otherExpensesHeader}>Diğer</div>
                        <NumberInput
                          className={classes.otherExpensesInput}
                          placeholder="Değer Giriniz"
                          value={manuelCalculation.other3}
                          onChange={(value) => {
                            setManuelCalculation({ ...manuelCalculation, other3: value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.otherExpensesTotal}>
                    Toplam: {numberWithCommas(Math.round(Object.values(calculationGroup.otherExpenses).reduce((a, b) => a + b, 0) * 100) / 100)} ₺
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className={classes.nameNoteWrapper}>
          <h3>Not</h3>
          <textarea
            contentEditable
            placeholder="Not Giriniz..."
            onChange={(e) => setCalculationGroup({ ...calculationGroup, note: e.target.value })}
            value={calculationGroup.note}
          />
        </div>
      </div>

      <div className={classes.calculationsButtonWrapper}>
        <button
          className={classes.calculationSaveButton}
          disabled={calculationGroup.note === ""}
          onClick={() => {
            const newCalcGroup = {
              ...calculationGroup,
              stockCalculations: calculationGroup.stockCalculations.map((c, i) => {
                c.pricePerAmount =
                  (((c.KKTC_KDV + c.boughtPriceAmount + c.cif + c.oils + c.otherExpensesPercentageShared + c.preCustoms) / c.currency) * (c.salesListPercentage + 100)) / 100;

                return c;
              }),
            };
            if (calculationGroup._stockCalculationId === 0) {
              postCalculation(newCalcGroup).then((res) => {
                setCalculationGroup({
                  ...newCalcGroup,
                  _stockCalculationId: res._stockCalculationId,
                  stockCalculations: newCalcGroup.stockCalculations.map((c, index) => {
                    c._calcId = res.stockCalculations[index]._calcId;
                    return c;
                  }),
                });
              });
            } else {
              updateCalculation(newCalcGroup);
            }
          }}
        >
          Kaydet
        </button>
        <button className={classes.calculationCancelButton} onClick={() => navigate(-1)}>
          İptal
        </button>
      </div>
      {calculationGroup.stockCalculations.length !== 0 ? (
        <div className={classes.accordionList}>
          <h2>Ürünler</h2>
          {calculationGroup.stockCalculations.map((calculation, index) => {
            return (
              <Accordion style={{ backgroundColor: calculation.status === "CLOSED" ? "#696766" : "" }} className={classes.accordionWrapper}>
                <AccordionSummary
                  expandIcon={
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13 16.172L18.364 10.808L19.778 12.222L12 20L4.222 12.222L5.636 10.808L11 16.172V4H13V16.172Z" fill="var(--secondary-color)" />
                    </svg>
                  }
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <div className={classes.accordionHeader}>
                    <div style={{ display: "flex", gap: "1rem", alignItems: "center", width: "100%" }}>
                      <ReactSelect
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary25: "var(--secondary-color)",
                            primary: "var(--primary-color)",
                          },
                        })}
                        options={calculationGroup.firms.map((firm) => ({
                          value: firm,
                          label: `${firm.firmName} - ${firm.billNo}`,
                        }))}
                        onChange={(option) => {
                          if (option) {
                            setCalculationGroup({
                              ...calculationGroup,
                              stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                                if (i === index) {
                                  return {
                                    ...calc,
                                    date: option.value.date,
                                    currency: option.value.currency,
                                    firmName: option.value.firmName,
                                  };
                                }
                                return calc;
                              }),
                            });
                          }
                        }}
                        placeholder="Firma Seçiniz"
                        value={calculationGroup.firms
                          .map((firm) => ({
                            value: firm,
                            label: `${firm.firmName} - ${firm.billNo}`,
                          }))
                          .find((option) => option.value.firmName === calculation.firmName)}
                        styles={{
                          control: (base) => ({
                            ...base,
                            minWidth: "200px",
                          }),
                        }}
                      />
                      <div>
                        {`${calculation.productCode.toUpperCase()} : ${calculation.productName.toUpperCase()} : ${
                          calculation.productBrand ? calculation.productBrand.toUpperCase() : ""
                        } ${calculation.productNote ? " :" + calculation.productNote.toUpperCase() : ""} `}
                      </div>
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{ borderTop: "2px solid var(--secondary-color)", paddingTop: "2rem" }} className={classes.totalValuesAccordionWrapper}>
                    <div className={classes.totalValues}>
                      <h4>Alınan Adet</h4>
                      <NumberInput
                        className={classes.calculationProductInput}
                        placeholder="Adet Giriniz"
                        disabled={calculation.status === "CLOSED"}
                        value={calculation.boughtAmount}
                        onChange={(value) => {
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              return {
                                ...calc,
                                boughtAmount: i === index ? value : calc.boughtAmount,
                                cif:
                                  ((calculationGroup.cifTotal.navlun + calculationGroup.cifTotal.ordino) * calc.boughtPriceAmount) /
                                  (totalBoughtPriceAmount + calculation.boughtAmount * (value - calculation.boughtAmount)),
                              };
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                    </div>
                    <div className={classes.totalValues}>
                      <h4>Alım Birim Fiyat</h4>
                      <NumberInput
                        className={classes.calculationProductInput}
                        disabled={calculation.status === "CLOSED"}
                        placeholder="Fiyat Giriniz"
                        value={calculation.boughtPriceAmount}
                        onChange={(value) => {
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              return {
                                ...calc,
                                boughtPriceAmount: i === index ? value : calc.boughtPriceAmount,
                                cif:
                                  ((calculationGroup.cifTotal.navlun + calculationGroup.cifTotal.ordino) * (i === index ? value : calc.boughtPriceAmount)) /
                                  (totalBoughtPriceAmount + calculation.boughtAmount * (value - calculation.boughtAmount)),
                              };
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                    </div>

                    <div className={classes.totalValues}>
                      <h4>Yağlar %</h4>
                      <NumberInput
                        className={classes.calculationProductInput}
                        disabled={!calculationGroup.isOilsPercentaged || calculation.status === "CLOSED"}
                        placeholder="Yüzde Değeri Giriniz"
                        value={calculation.oilsPercentage}
                        onChange={(value) => {
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              if (i === index) {
                                calc.oilsPercentage = value;
                              }
                              return calc;
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                      <div className={classes.currencyValuesWrapper}>
                        <div className={`${classes.currencyRow} ${classes.euro}`}>
                          <span>{numberWithCommas(Math.round((calculation.oils / calculation.currency) * 100) / 100)}</span>
                          <span>€</span>
                        </div>
                        <div className={`${classes.currencyRow} ${classes.lira}`}>
                          <span>{numberWithCommas(Math.round(calculation.oils * 100) / 100)}</span>
                          <span>₺</span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.totalValues}>
                      <h4>Gümrük %</h4>
                      <NumberInput
                        className={classes.calculationProductInput}
                        placeholder="Yüzde Değeri Giriniz"
                        disabled={!calculationGroup.isCustomsPercentaged || calculation.status === "CLOSED"}
                        value={calculation.preCustomsPercentage}
                        onChange={(value) => {
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              if (i === index) {
                                calc.preCustomsPercentage = value;
                              }
                              return calc;
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                      <div className={classes.currencyValuesWrapper}>
                        <div className={`${classes.currencyRow} ${classes.euro}`}>
                          <span>{numberWithCommas(Math.round((calculation.preCustoms / calculation.currency) * 100) / 100)}</span>
                          <span>€</span>
                        </div>
                        <div className={`${classes.currencyRow} ${classes.lira}`}>
                          <span>{numberWithCommas(Math.round(calculation.preCustoms * 100) / 100)}</span>
                          <span>₺</span>
                        </div>
                      </div>
                    </div>

                    <div className={classes.totalValues}>
                      <h4>Stopaj %</h4>
                      <NumberInput
                        className={classes.calculationProductInput}
                        placeholder="Yüzde Değeri Giriniz"
                        disabled={!calculationGroup.isStopajPercentaged || calculation.status === "CLOSED"}
                        value={calculation.stopajPercentage}
                        onChange={(value) => {
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              if (i === index) {
                                calc.stopajPercentage = value;
                              }
                              return calc;
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                      <div className={classes.currencyValuesWrapper}>
                        <div className={`${classes.currencyRow} ${classes.euro}`}>
                          <span>{numberWithCommas(Math.round((calculation.stopaj / calculation.currency) * 100) / 100)}</span>
                          <span>€</span>
                        </div>
                        <div className={`${classes.currencyRow} ${classes.lira}`}>
                          <span>{numberWithCommas(Math.round(calculation.stopaj * 100) / 100)}</span>
                          <span>₺</span>
                        </div>
                      </div>
                    </div>

                    <div className={classes.totalValues}>
                      <h4>KDV %</h4>
                      <NumberInput
                        className={classes.calculationProductInput}
                        step=".01"
                        placeholder="Yüzde Değeri Giriniz"
                        disabled={!calculationGroup.isKDVPercentaged || calculation.status === "CLOSED"}
                        value={calculation.KKTC_KDV_Percentage}
                        onChange={(value) => {
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calc, i) => {
                              if (i === index) {
                                calc.KKTC_KDV_Percentage = value;
                              }
                              return calc;
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                      <div className={classes.currencyValuesWrapper}>
                        <div className={`${classes.currencyRow} ${classes.euro}`}>
                          <span>{numberWithCommas(Math.round((calculation.KKTC_KDV / calculation.currency) * 100) / 100)}</span>
                          <span>€</span>
                        </div>
                        <div className={`${classes.currencyRow} ${classes.lira}`}>
                          <span>{numberWithCommas(Math.round(calculation.KKTC_KDV * 100) / 100)}</span>
                          <span>₺</span>
                        </div>
                      </div>
                    </div>
                    <div className={classes.totalValues}>
                      <h4>Maliyet Birim €</h4>
                      <p>
                        {numberWithCommas(
                          Math.round(
                            ((calculation.boughtPriceAmount +
                              calculation.cif +
                              calculation.oils +
                              calculation.stopaj +
                              calculation.otherExpensesPercentageShared +
                              calculation.preCustoms) /
                              calculation.currency) *
                              100
                          ) / 100
                        )}{" "}
                        €
                      </p>
                    </div>
                    <div className={classes.totalValues}>
                      <h4>Kar yüzdesi</h4>
                      <NumberInput
                        className={classes.calculationProductInput}
                        disabled={calculation.status === "CLOSED"}
                        placeholder="Yüzde Giriniz"
                        value={calculation.salesListPercentage}
                        onChange={(value) => {
                          setCalculationGroup({
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calculation, i) => {
                              if (i === index) {
                                return { ...calculation, salesListPercentage: value };
                              }
                              return calculation;
                            }),
                          });
                        }}
                      />
                    </div>
                    <div className={classes.totalValues}>
                      <h4>Liste Birim €</h4>
                      <p>
                        {numberWithCommas(
                          Math.round(
                            ((((calculation.boughtPriceAmount +
                              calculation.cif +
                              calculation.oils +
                              calculation.otherExpensesPercentageShared +
                              calculation.stopaj +
                              calculation.preCustoms) /
                              calculation.currency) *
                              (calculation.salesListPercentage + 100)) /
                              100) *
                              100
                          ) / 100
                        )}{" "}
                        €
                      </p>
                    </div>
                  </div>
                  <div className={classes.totalValuesWrapper}>
                    <div className={classes.totalValues}>
                      <h4>Döviz Kuru</h4>
                      <NumberInput
                        className={classes.calculationProductInput}
                        disabled={calculation.status === "CLOSED"}
                        placeholder="Döviz Kuru Giriniz"
                        value={calculationGroup.stockCalculations[index].currency}
                        onChange={(value) => {
                          const newCalcGroup = {
                            ...calculationGroup,
                            stockCalculations: calculationGroup.stockCalculations.map((calculation, i) => {
                              if (i === index) {
                                return { ...calculation, currency: value };
                              }
                              return calculation;
                            }),
                          };
                          reCalculatePercentagesWithNewCif_BoughtPrices(newCalcGroup);
                        }}
                      />
                    </div>
                    <button
                      className={classes.sendStockButton}
                      onClick={() => {
                        const newCalcGroup = {
                          ...calculationGroup,
                          stockCalculations: calculationGroup.stockCalculations.map((c, i) => {
                            c.pricePerAmount =
                              (((c.boughtPriceAmount + c.cif + c.oils + c.stopaj + c.otherExpensesPercentageShared + c.preCustoms) / c.currency) * (c.salesListPercentage + 100)) /
                              100;
                            if (i === index) {
                              c.status = "CLOSED";
                            }
                            return c;
                          }),
                        };
                        setCalculationGroup(newCalcGroup);
                        if (calculationGroup._stockCalculationId === 0) {
                          postCalculation(calculationGroup).then((res) => {
                            setCalculationGroup({
                              ...calculationGroup,
                              _stockCalculationId: res._stockCalculationId,
                              stockCalculations: calculationGroup.stockCalculations.map((c, index) => {
                                c._calcId = res.stockCalculations[index]._calcId;
                                return c;
                              }),
                            });
                          });
                        } else {
                          updateCalculation(newCalcGroup).then((res) =>
                            setCalculationGroup({
                              ...calculationGroup,
                              _stockCalculationId: res._stockCalculationId,
                              stockCalculations: calculationGroup.stockCalculations.map((c, index) => {
                                c._calcId = res.stockCalculations[index]._calcId;
                                return c;
                              }),
                            })
                          );
                        }
                      }}
                      disabled={calculation.status === "CLOSED" || calculationGroup.note === ""}
                    >
                      {calculation.status === "CLOSED" || calculationGroup.note === "" ? "Stokta" : "Stoğa Gönder"}
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};
