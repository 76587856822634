import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "../Pages/Home/Home";
import { WorkOrderPage } from "../Pages/WorkOrder/WorkOrderPage";
import {
  CALCULATION_DETAILS_ROUTE,
  DASHBOARD_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  MACHINES_ALL_ROUTE,
  STOCK_ALL_ROUTE,
  STOCK_CALCULATIONS_ROUTE,
  STOCK_ROUTE,
  WORK_ORDER_ALL_ROUTE,
  WORK_ORDER_ROUTE,
} from "../Constants/ROUTE_PATH_TITLES";
import { Stock } from "../Pages/Stock/Stock";
import { Login } from "../Pages/Login/Login";
import { ProtectedRoute } from "./ProtectedRoute";
import { GlobalState } from "../Redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import { WorkOrdersAll } from "../Pages/WorkOrdersAll/WorkOrdersAll";
import { StockAll } from "../Pages/StockAll/StockAll";
import API from "../api/API";
import { useEffect } from "react";
import { GOT_CURRENCY_FINALLY } from "../Redux/CurrencyActions";
import { Dashboard } from "../Pages/DashBoard/Dashboard";
import { MachinesAll } from "../Pages/MachinesAll/MachinesAll";
import { Currency } from "../Models/Currency";
import useDefaultWorkPrice from "../hooks/useDefaultWorkPrice";
import { GOT_DEFAULT_PRICE_FINALLY } from "../Redux/DefaultWorkPriceActions";
import { StockCalculationsPage } from "../Pages/StokCalculationsPage/StockCalculationsPage";
import { CalculationDetailsPage } from "../Pages/CalculationDetailsPage/CalculationDetailsPage";

const api = new API();

export const AppRouter = () => {
  const currency: Currency = useSelector((state: GlobalState) => state.currency);
  const dispatch = useDispatch();
  const { hookedDefaultWorkPrice, priceLoading } = useDefaultWorkPrice();

  useEffect(() => {
    if (currency.rate === 0) {
      api.getCurrency().then((response: Currency) => {
        dispatch(GOT_CURRENCY_FINALLY(response));
      });
    }
    if (!priceLoading) {
      dispatch(GOT_DEFAULT_PRICE_FINALLY(hookedDefaultWorkPrice));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, hookedDefaultWorkPrice, priceLoading]);

  return (
    <Router>
      <Routes>
        <Route
          path={`${HOME_ROUTE.PATH}/*`}
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${WORK_ORDER_ROUTE.PATH}/:customerId/:partName/:workOrderId/*`}
          element={
            <ProtectedRoute>
              <WorkOrderPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${WORK_ORDER_ALL_ROUTE.PATH}/*`}
          element={
            <ProtectedRoute>
              <WorkOrdersAll />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${STOCK_ALL_ROUTE.PATH}/*`}
          element={
            <ProtectedRoute>
              <StockAll />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${STOCK_ROUTE.PATH}/*`}
          element={
            <ProtectedRoute>
              <Stock />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${MACHINES_ALL_ROUTE.PATH}/*`}
          element={
            <ProtectedRoute>
              <MachinesAll />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${DASHBOARD_ROUTE.PATH}/*`}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${STOCK_CALCULATIONS_ROUTE.PATH}/*`}
          element={
            <ProtectedRoute>
              <StockCalculationsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${CALCULATION_DETAILS_ROUTE.PATH}/:calcId/*`}
          element={
            <ProtectedRoute>
              <CalculationDetailsPage />
            </ProtectedRoute>
          }
        />
        <Route path={`${LOGIN_ROUTE.PATH}`} element={<Login />} />
      </Routes>
    </Router>
  );
};
