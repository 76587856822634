import React from "react";
import "./DeletePopUp.scss";

interface IDeletePopUp {
  onClick: () => void;
  close: (closed: boolean) => void;
  header: string;
  children?: React.ReactNode;
}
export const DeletePopUp: React.FC<IDeletePopUp> = ({ close, header, onClick, children }) => {
  return (
    <div className="deleteBg">
      <div className="deleteWrapper">
        <h2 className="deleteHeader">{header}</h2>
        {children}
        <div className="buttonWrapper">
          <button
            className="deleteButton"
            onClick={() => {
              onClick();
              close(true);
            }}
          >
            Evet
          </button>
          <button className="CancelButton" onClick={() => close(true)}>
            Hayır
          </button>
        </div>
      </div>
    </div>
  );
};
