/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from "react";
import "./ErrorMessage.scss";

interface IErrorMessage {
  title?: string;
  message: string;
  onClose: () => void;
  closeMs?: number;
}

export const ErrorMessage: React.FC<IErrorMessage> = ({ title, message, onClose, closeMs = 0 }) => {
  useEffect(() => {
    let t: NodeJS.Timeout | undefined;
    if (closeMs !== 0) {
      t = setTimeout(() => {
        onClose();
      }, closeMs);
    }
    return () => {
      if (t) clearTimeout(t);
    };
  }, [closeMs, onClose]);

  return (
    <div className="notification">
      <button
        type="button"
        className="closeButton"
        onClick={() => {
          onClose();
        }}
      >
        X
      </button>
      <h4>{title || "Error"}</h4>
      <span>{message}</span>
    </div>
  );
};
