import { useState } from "react";
import { Machine } from "../../../Models/Machine";
import "./AddMachinePopUp.scss";

interface IAddCustomerPopUp {
  createdMachine: (machine: Machine) => void;
  customerId: number;
  close: (closed: boolean) => void;
  machinetoBeEdited?: Machine;
}

export const AddMachinePopUp: React.FC<IAddCustomerPopUp> = ({ createdMachine, close, customerId, machinetoBeEdited }) => {
  const initState = (): Machine => {
    if (machinetoBeEdited) {
      return machinetoBeEdited;
    } else {
      return { brand: "", modal: "", seriesNumber: "", productNumber: "", qCode: "", year: 0, _customerId: customerId };
    }
  };
  const [machine, setMachine] = useState<Machine>(initState());

  return (
    <div className="AddMachinePopUpBg">
      <div className="AddMachinePopUpWrapper">
        <div className="AddPopUpHeader">Makine Bilgilerini Giriniz</div>

        <div className="AddPopUpContent">
          Marka:
          <input
            className="MachineInput"
            defaultValue={machine.brand}
            placeholder="Marka giriniz..."
            type="text"
            onChange={(e) => setMachine({ ...machine, brand: e.target.value })}
          />
          Model:{" "}
          <input
            className="MachineInput"
            defaultValue={machine.modal}
            placeholder="Model giriniz..."
            type="text"
            onChange={(e) => setMachine({ ...machine, modal: e.target.value })}
          />
          Seri Numarası:
          <input
            className="MachineInput"
            defaultValue={machine.seriesNumber}
            placeholder="Seri Numarası giriniz..."
            type="text"
            onChange={(e) => setMachine({ ...machine, seriesNumber: e.target.value })}
          />
          Q Kodu:
          <input
            defaultValue={machine.qCode}
            className="MachineInput"
            placeholder="Q Kodu giriniz..."
            type="text"
            onChange={(e) => setMachine({ ...machine, qCode: e.target.value })}
          />
          Ürün Numarası:
          <input
            defaultValue={machine.productNumber}
            className="MachineInput"
            placeholder="Ürün Numarası giriniz..."
            type="text"
            onChange={(e) => {
              setMachine({ ...machine, productNumber: e.target.value });
            }}
          />
          Yılı:
          <input
            className="MachineInput"
            placeholder="Yıl giriniz..."
            type="number"
            defaultValue={machine.year}
            onChange={(e) => {
              if (!isNaN(parseInt(e.target.value))) {
                setMachine({ ...machine, year: parseInt(e.target.value) });
              } else {
                setMachine({ ...machine, year: 0 });
              }
            }}
          />
        </div>
        <span className="buttonWrapper">
          <button
            disabled={machine.brand === "" || machine.modal === "" || machine.seriesNumber === "" || machine.productNumber === "" || machine.qCode === "" || machine.year === 0}
            className="addBtn"
            onClick={() => {
              close(true);
              createdMachine(machine);
            }}
          >
            {machinetoBeEdited ? <>Makineyi Düzenle</> : <>Makineyi Ekle</>}
          </button>
          <button
            className="cancelBtn"
            onClick={() => {
              close(true);
            }}
          >
            İptal
          </button>
        </span>
      </div>
    </div>
  );
};
