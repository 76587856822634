import { useState } from "react";
import API from "../api/API";
import { Product } from "../Models/Product";

const api = new API();

const useProduct = () => {
  const [productsLoading, setProductsLoading] = useState<boolean>(false);

  const getAllProducts = (page: number, limit: number, search: string, onlyWarnings?: 0 | 1) => {
    setProductsLoading(true);
    return api
      .getProducts(page, limit, search, onlyWarnings)
      .then((result) => {
        return result;
      })
      .finally(() => setProductsLoading(false))
      .catch((err) => console.error(err));
  };

  const updateProduct = (product: Product) => {
    setProductsLoading(true);
    return api
      .putProduct(product)
      .then((result) => {
        return result;
      })
      .finally(() => setProductsLoading(false))
      .catch((err) => console.error(err));
  };

  const addProduct = (product: Product) => {
    setProductsLoading(true);
    return api
      .postProduct(product)
      .then((result) => {
        return result;
      })
      .finally(() => setProductsLoading(false))
      .catch((err) => console.error(err));
  };

  return { getAllProducts, productsLoading, updateProduct, addProduct };
};

export default useProduct;
