import { ThreeCircles } from "react-loader-spinner";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./MachinesAll.scss";
import { useEffect, useRef, useState } from "react";
import { Customer } from "../../Models/Customer";
import { MACHINES_ALL_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import useCustomer from "../../hooks/useCustomer";
import { API_LIMIT } from "../../Constants/ENUMS";

const nestedSort =
  (prop1: any, prop2: any, direction = "asc") =>
  (e1: any, e2: any) => {
    const a = prop2 ? e1[prop1][prop2] : e1[prop1],
      b = prop2 ? e2[prop1][prop2] : e2[prop1],
      sortOrder = direction === "asc" ? 1 : -1;
    return a < b ? -sortOrder : a > b ? sortOrder : 0;
  };

export const MachinesAll = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pageCount, setPageCount] = useState<number>(1);

  const { searchCustomers, customersLoading } = useCustomer();

  const timerRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (searchTerm.length === 0) {
      searchCustomers(pageCount, API_LIMIT, searchTerm)
        .then((result) => {
          if (pageCount === 1) {
            setCustomers(result);
          } else {
            setCustomers(customers.concat(result));
          }
        })
        .catch((err) => console.error(err));
    }
    if (searchTerm.length > 2) {
      if (timerRef.current) window.clearTimeout(timerRef.current);

      timerRef.current = window.setTimeout(() => {
        searchCustomers(pageCount, API_LIMIT, searchTerm)
          .then((result) => {
            if (pageCount === 1) {
              setCustomers(result);
            } else {
              setCustomers(customers.concat(result));
            }
          })
          .catch((err) => console.error(err));
      }, 600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, searchTerm]);

  return (
    <div>
      <Navbar />

      <div className="machinesAllWrapper">
        <div className="machinesAllHeaderWrapper">
          <h1 className="machinesAllHeader">{MACHINES_ALL_ROUTE.TITLE}</h1>
        </div>
        <div className="searchLoaderWrapper">
          <div className="group">
            <svg viewBox="0 0 24 24" fill="white" aria-hidden="true" className="searchIcon">
              <g>
                <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
              </g>
            </svg>
            <input
              onChange={(e) => {
                setSearchTerm(e.target.value.toUpperCase());
                setPageCount(1);
              }}
              className="input"
              type="text"
              placeholder="Ara..."
            />
          </div>
          {customersLoading ? (
            <div>
              <ThreeCircles height="60" width="60" color="var(--primary-color)" />
            </div>
          ) : null}
        </div>

        <table className="fl-table">
          <thead>
            <tr>
              <th>Marka</th>
              <th>Model</th>
              <th>Seri Numarası</th>
              <th>Yılı</th>
              <th>Firma</th>
            </tr>
          </thead>
          <tbody>
            {customers
              .map((customer) => {
                return customer.machines.map((machine) => {
                  return {
                    customerName: customer.name,
                    machine: machine,
                  };
                });
              })
              .flat(1)
              .sort(nestedSort("machine", "brand"))
              .map((flatCustomer, index) => {
                return (
                  <tr key={`${flatCustomer.customerName} - ${index}`}>
                    <td>{flatCustomer.machine.brand.toUpperCase()}</td>
                    <td>{flatCustomer.machine.modal.toUpperCase()}</td>
                    <td>{flatCustomer.machine.seriesNumber.toUpperCase()}</td>
                    <td>{flatCustomer.machine.year}</td>
                    <td>{flatCustomer.customerName.toUpperCase()}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <button onClick={() => setPageCount(pageCount + 1)} className="loadMoreButton">
          Daha fazla yükle
        </button>
      </div>
    </div>
  );
};
