import React, { useCallback, useEffect, useRef, useState } from "react";
import { CustomerDetails } from "../../Models/CustomerDetails";
import { WorkOrder } from "../../Models/WorkOrder";
import classes from "./OrderPart.module.scss";
import { Product } from "../../Models/Product";
import { AddWorkOrderPopUp } from "../PopUps/AddWorkOrderPopUp/AddWorkOrderPopUp";

import { OrderCard } from "./OrderCard/OrderCard";
import { DeletePopUp } from "../PopUps/DeletePopUp/DeletePopUp";
import { useSelector } from "react-redux";
import { GlobalState } from "../../Redux/reducers";
import { useNavigate } from "react-router";
import { WORK_ORDER_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import useProduct from "../../hooks/useProduct";
import { API_LIMIT } from "../../Constants/ENUMS";

interface IOrderPart {
  customerDetails: CustomerDetails;
  handleUpdatedWorkOrder: (WorkOrder: WorkOrder, justNumberSet?: 0 | 1) => void;
  handleAddedWorkOrder: (workOrder: WorkOrder) => void;
  handleDeleted: (WorkOrder: WorkOrder) => void;
  orderToOpen?: string;
  defaultWorkPrice: number;
}

export const OrderPart: React.FC<IOrderPart> = ({ customerDetails, handleUpdatedWorkOrder, handleAddedWorkOrder, handleDeleted, orderToOpen, defaultWorkPrice }) => {
  const [orderOpen, setOrderOpen] = useState<boolean>(false);
  const [workOrderToOperate, setWorkOrderToOperate] = useState<WorkOrder>();
  const [searchedProducts, setSearchedProducts] = useState<Product[]>([]);

  //-1= DEACTIVE, 0 = DELETE, 1 = EDIT
  const [popUpClicked, setPopUpClicked] = useState<-1 | 0 | 1>(-1);
  const [filter, setFilter] = useState<"OFFER" | "OPEN" | "CLOSED">("OPEN");
  const currency: number = useSelector((state: GlobalState) => state.currency.rate);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const { productsLoading, getAllProducts } = useProduct();

  const timerRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    if (orderToOpen && parseInt(orderToOpen) !== 0) {
      setWorkOrderToOperate(customerDetails.workOrders.find((workOrder) => workOrder._id === parseInt(orderToOpen)));
      setPopUpClicked(1);
    }
  }, [customerDetails.workOrders, orderToOpen]);

  const searchProducts = useCallback(
    (searchTerm: string) => {
      if (searchTerm.length > 2) {
        if (timerRef.current) window.clearTimeout(timerRef.current);

        timerRef.current = window.setTimeout(() => {
          getAllProducts(1, API_LIMIT, searchTerm)
            .then((result) => {
              setSearchedProducts(result);
            })
            .catch((err) => console.error(err));
        }, 600);
      }
    },
    [getAllProducts]
  );

  return (
    <div className={classes.workOrdersWrapper}>
      <>
        <div className={classes.customerDetailsHeaderWrapper}>
          <div className={classes.customerDetailsHeader}>{customerDetails.name}</div>
          <div className={classes.customerDetailsId}>MŞ. {customerDetails._customerId}</div>
          <span className={classes.orderPartButtonsWrapper}>
            <span>
              <button disabled={user[0]?.type !== "SUPER_ADMIN"} onClick={() => setOrderOpen(true)}>
                Yeni İş Emri Aç
              </button>
            </span>
            <span className={classes.orderPartFiltersWrapper}>
              <input type="checkbox" checked={filter === "OPEN"} id="OPEN_FILTER" onChange={() => setFilter("OPEN")} />
              <label htmlFor="OPEN_FILTER">Açık Emirler</label>
              <input type="checkbox" checked={filter === "OFFER"} id="OFFER_FILTER" onChange={() => setFilter("OFFER")} />
              <label htmlFor="OFFER_FILTER">Teklifler</label>

              <input type="checkbox" checked={filter === "CLOSED"} id="CLOSED_FILTER" onChange={() => setFilter("CLOSED")} />
              <label htmlFor="CLOSED_FILTER">Kapalı Emirler</label>
            </span>
          </span>
        </div>

        {/* CAN BE SHORTENED */}
        {orderOpen ? (
          <AddWorkOrderPopUp
            customerPercentages={customerDetails.defaultPercentages}
            machines={customerDetails.machines}
            products={searchedProducts}
            searchProducts={searchProducts}
            productsLoading={productsLoading}
            closed={(close) => setOrderOpen(!close)}
            createdWorkOrder={handleAddedWorkOrder}
            customerAddress={customerDetails.address}
            customerName={customerDetails.name}
            currency={currency}
            defaultWorkPrice={defaultWorkPrice}
          />
        ) : null}
        {popUpClicked === 1 && workOrderToOperate ? (
          <AddWorkOrderPopUp
            defaultWorkPrice={defaultWorkPrice}
            customerPercentages={customerDetails.defaultPercentages}
            machines={customerDetails.machines}
            productsLoading={productsLoading}
            products={searchedProducts}
            searchProducts={searchProducts}
            updatedWorkOrder={handleUpdatedWorkOrder}
            closed={() => {
              if (orderToOpen && parseInt(orderToOpen) !== 0) {
                navigate(`${WORK_ORDER_ROUTE.PATH}/${customerDetails._customerId}/WorkOrders/0`);
              }
              setPopUpClicked(-1);
            }}
            workOrdertoOperate={workOrderToOperate}
            customerAddress={customerDetails.address}
            customerName={customerDetails.name}
            currency={currency}
          />
        ) : null}
        {popUpClicked === 0 && workOrderToOperate ? (
          <DeletePopUp onClick={() => handleDeleted(workOrderToOperate)} close={() => setPopUpClicked(-1)} header={"İş Emrini Silmek İstediğinizden Emin misiniz"} />
        ) : null}
        <div className={classes.orderCardsWrapper}>
          {customerDetails.workOrders ? (
            <>
              {customerDetails.workOrders
                .filter((workOrder) => workOrder.status === filter)
                .map((workorder, index) => (
                  <div key={index}>
                    <OrderCard
                      currency={workorder.currency === "EUR" ? 1 : currency}
                      customerId={customerDetails._customerId}
                      handleDelete={(workorder) => {
                        setPopUpClicked(0);
                        setWorkOrderToOperate(workorder);
                      }}
                      handleEdit={(workOrder) => {
                        setPopUpClicked(1);
                        setWorkOrderToOperate(workOrder);
                      }}
                      customerAddress={customerDetails.address}
                      customerName={customerDetails.name}
                      updatedCard={handleUpdatedWorkOrder}
                      orderCardContent={workorder}
                    />
                  </div>
                ))}
            </>
          ) : null}
        </div>
      </>
    </div>
  );
};
