interface IRouteProps {
  PATH: string;
  TITLE: string;
}

export const HOME_ROUTE: IRouteProps = { PATH: "/", TITLE: "Ana Sayfa" };
export const WORK_ORDER_ROUTE: IRouteProps = { PATH: "/WorkOrder", TITLE: "İş Emirleri" };
export const STOCK_ROUTE: IRouteProps = { PATH: "/Stock", TITLE: "Stok İşlem" };
export const LOGIN_ROUTE: IRouteProps = { PATH: "/Login", TITLE: "Login" };
export const WORK_ORDER_ALL_ROUTE: IRouteProps = { PATH: "/WorkOrderAll", TITLE: "Tüm İş Emirleri" };
export const STOCK_ALL_ROUTE: IRouteProps = { PATH: "/StockAll", TITLE: "Tüm Stok" };
export const DASHBOARD_ROUTE: IRouteProps = { PATH: "/dashboard", TITLE: "Dashboard" };
export const MACHINES_ALL_ROUTE: IRouteProps = { PATH: "/machines", TITLE: "Makineler" };
export const STOCK_CALCULATIONS_ROUTE: IRouteProps = { PATH: "/stockCalculations", TITLE: "Stok Giriş" };
export const CALCULATION_DETAILS_ROUTE: IRouteProps = { PATH: "/calculationDetails", TITLE: "Gümrük Hesaplama" };
