import { Reducer } from "redux";

interface IAction {
  type: string;
  payload: number;
}

const DefaultWorkPriceReducer: Reducer<number, IAction> = (state: number = 0, action: IAction): number => {
  switch (action.type) {
    case "GOT_DEFAULT_PRICE_FINALLY":
      state = action.payload;
      return state;

    default:
      return state;
  }
};

export default DefaultWorkPriceReducer;
