import { useState } from "react";
import API from "../../api/API";
import { ThreeCircles } from "react-loader-spinner";
import "./Login.scss";
import { useNavigate } from "react-router";
import { HOME_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import { ErrorMessage } from "../../Components/ErrorMessage/ErrorMessage";

const api = new API();

export const Login = () => {
  const [initUser, setInitUser] = useState<{ name: string; password: string; type: "REGULAR" | "ADMIN" | "SUPERADMIN" }>({
    name: "",
    type: "REGULAR",
    password: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorShown, setErrorShown] = useState<boolean>(false);
  const navigate = useNavigate();

  const logInHandler = () => {
    setIsLoading(true);

    api
      .postLogin(initUser.name, initUser.password)
      .catch(() => setErrorShown(true))
      .finally(() => {
        api
          .getUser(initUser.name)
          .then((data) => {
            /* dispatch(LOGIN_FINALLY(data)); */
            localStorage.setItem("user", JSON.stringify(data));
            navigate(`${HOME_ROUTE.PATH}`);
          })
          .finally(() => setIsLoading(false));
      });
  };

  return (
    <div className="logInBg">
      {errorShown ? <ErrorMessage closeMs={5000} title="Hata!" message="Kullanıcı Adı veya Şifre Yanlış" onClose={() => setErrorShown(false)} /> : null}
      {isLoading ? (
        <div className="LoaderWrapper">
          <ThreeCircles height="80" width="80" color="var(--primary-color)" />
        </div>
      ) : (
        <div className="logInWrapper">
          <div className="logInItem">
            <div className="logInHeader">Kullanıcı Adı</div>
            <div>
              <input
                className="logInInput"
                type="text"
                onChange={(e) => {
                  setInitUser({ ...initUser, name: e.target.value.trim() });
                }}
              />
            </div>
          </div>
          <div className="logInItem">
            <div className="logInHeader">Şifre</div>
            <div>
              <input
                className="logInInput"
                type="password"
                onChange={(e) => {
                  setInitUser({ ...initUser, password: e.target.value.trim() });
                }}
              />
            </div>
          </div>
          <div>
            <button disabled={initUser.name === "" || initUser.password === ""} onClick={logInHandler} className="addBtn">
              Giriş Yap
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
