import { useEffect, useState } from "react";
import { Product } from "../../Models/Product";
import API from "../../api/API";
import { WorkOrder } from "../../Models/WorkOrder";
import "./StockCard.scss";
import { EditProductPopUp } from "../PopUps/EditProductPopUp/EditProductPopUp";
import { ProductEntries } from "../../Models/ProductEntries";
import { DeletePopUp } from "../PopUps/DeletePopUp/DeletePopUp";
import { ThreeCircles } from "react-loader-spinner";
import { Tooltip as ReactTooltip } from "react-tooltip";
import moment from "moment";

interface IStockCard {
  product: Product;
  updateProduct: (product: ProductEntries[], index: number, deleted: boolean) => void;
  functionsDisabled?: boolean;
}

const api = new API();

export const StockCard: React.FC<IStockCard> = ({ product, updateProduct, functionsDisabled }) => {
  const [productWorkOrders, setProductWorkOrders] = useState<WorkOrder[]>([]);
  const [editSelected, setEditSelected] = useState<{ open: boolean; entry: ProductEntries; index: number }>();
  const [deleteSelected, setDeleteSelected] = useState<{ open: boolean; index: number }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  console.log("denemee", product);

  useEffect(() => {
    setIsLoading(true);
    api
      .getWorkOrdersbyProduct(product._productId)
      .then((result) => {
        setProductWorkOrders(result);
      })
      .finally(() => setIsLoading(false));
  }, [product._productId]);

  return (
    <div>
      {isLoading ? (
        <div className="LoaderWrapper">
          <ThreeCircles height="80" width="80" color="var(--primary-color)" />
        </div>
      ) : null}
      {deleteSelected?.open ? (
        <DeletePopUp
          onClick={() => {
            if (updateProduct) {
              updateProduct(
                product.productEntries.filter((_wt, filterIndex) => filterIndex !== deleteSelected.index),
                deleteSelected.index,
                true
              );
            }
          }}
          close={() => setDeleteSelected({ ...deleteSelected, open: false })}
          header={"Ürün Girişini Silmek İstediğinizden Emin misiniz?"}
        />
      ) : null}
      {editSelected?.open ? (
        <EditProductPopUp
          propsProductEntry={editSelected.entry}
          close={() => setEditSelected({ ...editSelected, open: false })}
          editedProductEntry={(prodEntry) => {
            if (updateProduct) {
              const tempProdEntries = [...product.productEntries];
              tempProdEntries[editSelected.index] = prodEntry;
              updateProduct(tempProdEntries, editSelected.index, false);
            }
          }}
        />
      ) : null}
      <h2 className="stockCardHeader">
        {product.code} - {product.name}
      </h2>
      <div className="productCardInfoWrapper">
        {product.note ? (
          <div className="productCardInfo">
            <h3>Not</h3>
            <p className="productNote">{product.note}</p>
          </div>
        ) : null}
        {product.shelf ? (
          <div className="productCardInfo">
            <h3>Raf</h3>
            <p className="productNote">{product.shelf}</p>
          </div>
        ) : null}

        {product.minAmount ? (
          <div className="productCardInfo">
            <h3>Minimum Adet</h3>

            <p className="productNote">{product.minAmount}</p>
          </div>
        ) : null}
      </div>

      {product.minAmount && product.minAmount > product.productEntries.reduce((a, b) => a + b.currentAmount, 0) ? (
        <div className="stockCardWarningWrapper">
          <svg width="30" height="30" viewBox="0 0 20 20" fill="none" data-tooltip-id={`stockInfoTooltip-${product._productId}`} xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.0001 7.00005C11.0001 7.55233 10.5523 8.00005 10.0001 8.00005C9.44778 8.00005 9.00006 7.55233 9.00006 7.00005C9.00006 6.44776 9.44778 6.00005 10.0001 6.00005C10.5523 6.00005 11.0001 6.44776 11.0001 7.00005Z"
              fill="orange"
            />
            <path
              d="M10.0001 9.75005C10.4143 9.75005 10.7501 10.0858 10.7501 10.5V15.5C10.7501 15.9143 10.4143 16.25 10.0001 16.25C9.58585 16.25 9.25006 15.9143 9.25006 15.5V10.5C9.25006 10.0858 9.58585 9.75005 10.0001 9.75005Z"
              fill="orange"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.2704 1.99268C11.1784 0.39502 8.82165 0.395014 7.72967 1.99268L7.29775 2.62463C4.59171 6.58384 2.26675 10.7905 0.354018 15.1881L0.263803 15.3956C-0.410252 16.9453 0.608112 18.705 2.28766 18.8927C7.41329 19.4656 12.5868 19.4656 17.7124 18.8927C19.392 18.705 20.4103 16.9453 19.7363 15.3956L19.6461 15.1881C17.7333 10.7905 15.4084 6.58385 12.7023 2.62463L12.2704 1.99268ZM8.96806 2.83909C9.46441 2.11288 10.5357 2.11288 11.032 2.83909L11.4639 3.47104C14.1165 7.35206 16.3956 11.4756 18.2705 15.7864L18.3608 15.9938C18.6322 16.6179 18.2221 17.3264 17.5458 17.402C12.5309 17.9625 7.46917 17.9625 2.45427 17.402C1.77797 17.3264 1.36791 16.6179 1.63933 15.9938L1.72954 15.7864C3.6045 11.4756 5.88354 7.35205 8.53613 3.47104L8.96806 2.83909Z"
              fill="orange"
            />
          </svg>
          <ReactTooltip
            variant="warning"
            style={{ fontWeight: 600 }}
            id={`stockInfoTooltip-${product._productId}`}
            place="right"
            content={`Ürünün girişlerinin toplam anlık adedi (${product.productEntries.reduce((a, b) => a + b.currentAmount, 0)}) minimum adedinden (${product.minAmount}) az!`}
          />
        </div>
      ) : null}
      <div className="table-wrapper">
        <table className="fl-table">
          <thead>
            <tr>
              <th>Tarih</th>
              <th>Alınan Adet</th>
              <th>Anlık Adet</th>
              <th>Birim Giriş Fiyatı</th>
              <th>Birim Gümrük ve Masraflar</th>
              {/*               <th>Stopaj</th>
               */}{" "}
              <th>Birim KKTC KDV</th>
              <th>Birim Depo Maliyeti</th>
              {/*               <th>Sabit Fiyat</th>
               */}{" "}
              <th>Birim Satış Liste Yüzdesi</th>
              <th>Birim Liste Tutarı</th>
              <th>Göster - Düzenle - Sil</th>
            </tr>
          </thead>
          <tbody>
            {product.productEntries.length === 0 ? (
              <tr>
                <td>Bu ürünün henüz girişi yapılmamış</td>
              </tr>
            ) : (
              product.productEntries.map((entry, index) => {
                return (
                  <tr key={index}>
                    <td>{moment(entry.date).format("DD/MM/YYYY")}</td>
                    <td>{entry.boughtAmount}</td>
                    <td>{entry.currentAmount}</td>
                    <td>{entry.boughtPriceAmount}</td>
                    <td>{entry.customs}</td>
                    {/*                     <td>{entry.stopaj}</td>
                     */}{" "}
                    <td>{entry.KKTC_KDV}</td>
                    <td>{Math.round((entry.customs + entry.boughtPriceAmount) * 100) / 100}</td>
                    {/*                     <td>{Math.round((entry.boughtPriceAmount + entry.customs)  * 100) / 100}</td>
                     */}
                    <td>%{entry.salesListPercentage}</td>
                    <td>{entry.pricePerAmount}</td>
                    <td>
                      <button className="stockCardIconsWrapper" disabled={functionsDisabled}>
                        <input
                          type="checkbox"
                          checked={entry.show}
                          className="stockCardCheckbox"
                          onChange={() => {
                            product.productEntries[index].show = !product.productEntries[index].show;
                            updateProduct(product.productEntries, index, false);
                          }}
                        />
                        <div
                          onClick={() => {
                            setEditSelected({ open: true, entry: entry, index: index });
                          }}
                        >
                          <svg width="20" height="22" viewBox="0 0 20 22" fill="#f37826" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.81 6.94L11.06 3.19L2 12.25V16H5.75L14.81 6.94ZM4 14V13.08L11.06 6.02L11.98 6.94L4.92 14H4Z" />
                            <path d="M17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.41 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04Z" />
                            <path d="M20 18H0V22H20V18Z" />
                          </svg>
                        </div>
                        <div
                          onClick={() => {
                            setDeleteSelected({ open: true, index: index });
                          }}
                        >
                          <svg width="14" height="18" viewBox="0 0 14 18" fill="red" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 6V16H3V6H11ZM9.5 0H4.5L3.5 1H0V3H14V1H10.5L9.5 0ZM13 4H1V16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4Z" />
                          </svg>
                        </div>
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>

      <div className="table-wrapper">
        <table className="fl-table">
          <thead>
            <tr>
              <th>Parçanın Satıldığı Makine</th>
              <th>Açıklama</th>
              <th>Satış</th>
              <th>Satış Durum</th>
            </tr>
          </thead>
          <tbody>
            {productWorkOrders.filter((prodWorkOrder) => prodWorkOrder.status !== "OFFER").length === 0 ? (
              <tr>
                <td>Bu ürünün henüz satışı yapılmamış</td>
              </tr>
            ) : (
              productWorkOrders
                .filter((prodWorkOrder) => prodWorkOrder.status !== "OFFER")
                .map((productWorkOrder, index) => (
                  <tr key={index}>
                    <td>{productWorkOrder.Machine.modal}</td>
                    <td>{productWorkOrder.description}</td>
                    <td>
                      {productWorkOrder.productsToBeSold.filter((prodToSell) => prodToSell.product._productId === product._productId).reduce((a, b) => a + b.toBeSoldAmount, 0)}
                    </td>
                    <td>{productWorkOrder.status === "OFFER" ? "TEKLİF" : productWorkOrder.status === "OPEN" ? "AÇIK" : "KAPALI"}</td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
