import { useState } from "react";
import API from "../api/API";
import { API_LIMIT } from "../Constants/ENUMS";
import { CalculationGroup } from "../Models/CalculationGroup";

const api = new API();

const useCalculation = () => {
  const [calculationsLoading, setCalculationsLoading] = useState<boolean>(false);

  const getCalculations = (page: number = 1, limit: number = API_LIMIT) => {
    setCalculationsLoading(true);
    return api
      .getCalculations(page, limit)
      .then((response) => {
        return response;
      })
      .finally(() => setCalculationsLoading(false))
      .catch((err) => console.error(err));
  };

  const getCalculationDetails = (calculationId: number) => {
    setCalculationsLoading(true);
    return api
      .getCalcDetails(calculationId)
      .then((response) => {
        return response;
      })
      .finally(() => setCalculationsLoading(false))
      .catch((err) => console.error(err));
  };

  const postCalculation = (calculation: CalculationGroup) => {
    setCalculationsLoading(true);
    return api
      .postCalculation(calculation)
      .then((response) => {
        return response;
      })
      .finally(() => setCalculationsLoading(false))
      .catch((err) => console.error(err));
  };

  const updateCalculation = (calculation: CalculationGroup) => {
    setCalculationsLoading(true);
    return api
      .putCalculation(calculation)
      .then((response) => {
        return response;
      })
      .finally(() => setCalculationsLoading(false))
      .catch((err) => console.error(err));
  };

  return { calculationsLoading, getCalculationDetails, getCalculations, postCalculation, updateCalculation };
};

export default useCalculation;
