import { useDispatch, useSelector } from "react-redux";
import API from "../api/API";
import { useEffect, useState } from "react";
import { GOT_DEFAULT_PRICE_FINALLY } from "../Redux/DefaultWorkPriceActions";
import { GlobalState } from "../Redux/reducers";

const api = new API();

const useDefaultWorkPrice = () => {
  const [hookedDefaultWorkPrice, setHookedDefaultWorkPrice] = useState<number>(0);
  const [priceLoading, setPriceLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  const defaultWorkPrice: number = useSelector((state: GlobalState) => state.defaultWorkPrice);

  useEffect(() => {
    if (defaultWorkPrice === 0) {
      api
        .getDefaultWorkPrice()
        .then((result) => {
          setHookedDefaultWorkPrice(result.defaultWorkPrice.defaultWorkPrice);
        })
        .finally(() => setPriceLoading(false))
        .catch((err) => {
          console.error(err);
        });
    } else {
      setPriceLoading(false);
    }
  }, []);

  const updateDefaultWorkPrice = (updatedPrice: number) => {
    setPriceLoading(true);
    api
      .updateDefaultWorkPrice(updatedPrice)
      .then(() => {
        setHookedDefaultWorkPrice(updatedPrice);
        dispatch(GOT_DEFAULT_PRICE_FINALLY(updatedPrice));
      })
      .finally(() => setPriceLoading(false))
      .catch((err) => {
        console.error(err);
      });
  };

  return { hookedDefaultWorkPrice, priceLoading, updateDefaultWorkPrice };
};

export default useDefaultWorkPrice;
