import { useCallback, useEffect, useRef, useState } from "react";
import { Product } from "../../Models/Product";
import ReactSelect, { MultiValue, SingleValue } from "react-select";
import { StockCard } from "../../Components/StockCard/StockCard";
import { Navbar } from "../../Components/Navbar/Navbar";
import "./Stock.scss";
import { ThreeCircles } from "react-loader-spinner";
import { ProductEntries } from "../../Models/ProductEntries";
import { EditProductPopUp } from "../../Components/PopUps/EditProductPopUp/EditProductPopUp";
import { AddProductPopUp } from "../../Components/PopUps/AddProductPopUp/AddProductPopUp";
import { STOCK_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import useProduct from "../../hooks/useProduct";
import { API_LIMIT } from "../../Constants/ENUMS";

export type RSelectOption = {
  value: Product;
  label: string;
};

export const Stock = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedOption, setSelectedOptions] = useState<RSelectOption>();

  // -1 = DEACTIVATED, 0 = ADD/EDIT PRODUCT CARD, 1 = ADD PRODUCT ENTRY
  const [addSelected, setAddSelected] = useState<{
    selected: -1 | 0 | 1;
    currentProd?: Product;
    editedProduct?: (prod: Product) => void;
    addProduct?: (prod: Product) => void;
  }>({
    selected: -1,
  });
  /*   const [deleteSelected, setDeleteSelected] = useState<boolean>(false);
   */

  const { productsLoading, getAllProducts, addProduct, updateProduct } = useProduct();

  const timerRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    getAllProducts(1, API_LIMIT, "").then((prods) => setProducts(prods));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedOptionChange = (newSelectedOption: MultiValue<RSelectOption> | SingleValue<RSelectOption>) => {
    setSelectedOptions(newSelectedOption as RSelectOption);
  };

  const handleAddProductEntry = (productEntry: ProductEntries) => {
    //can be shortened
    if (selectedOption?.value) {
      updateProduct({
        ...selectedOption?.value,
        amount: selectedOption.value.amount + productEntry.boughtAmount,
        minAmount: productEntry.minAmount,
        shelf: productEntry.shelf,
        pricePerProduct:
          Math.round(
            ([...selectedOption.value.productEntries, productEntry]
              .map((item) => {
                return item.pricePerAmount * item.boughtAmount;
              })
              .reduce((a, b) => a + b, 0) /
              [...selectedOption.value.productEntries, productEntry].reduce((a, b) => a + b.boughtAmount, 0)) *
              100
          ) / 100,
        productEntries: [...selectedOption.value.productEntries, productEntry],
      }).then((updatedProduct) => handleSelectedOptionChange({ value: updatedProduct, label: selectedOption.label }));
    }
  };
  const handleAddProduct = (product: Product) => {
    addProduct(product);
  };

  /*   const handleDeleteProduct = (product: Product) => {
    setIsLoading(true);
    api
      .deleteProduct(product._productId)
      .then(() =>
        api.getProducts().then((data) => {
          setSelectedOptions(undefined);
          setProducts(data);
        })
      )
      .finally(() => setIsLoading(false));
  }; */

  const handleEditedProductEntry = (entries: ProductEntries[], index: number, deleted: Boolean) => {
    //can be shortened
    if (selectedOption?.value) {
      updateProduct({
        ...selectedOption.value,
        productEntries: entries,
      }).then((updatedProduct) => handleSelectedOptionChange({ value: updatedProduct, label: selectedOption.label }));
    }
  };

  const handleEditedProduct = (editedProduct: Product) => {
    if (selectedOption?.value) {
      updateProduct(editedProduct).then((updatedProduct) => handleSelectedOptionChange({ value: updatedProduct, label: selectedOption.label }));
    }
  };

  const searchProducts = useCallback(
    (searchTerm: string) => {
      if (searchTerm.length > 2) {
        if (timerRef.current) window.clearTimeout(timerRef.current);

        timerRef.current = window.setTimeout(() => {
          getAllProducts(1, API_LIMIT, searchTerm)
            .then((result) => {
              setProducts(result);
            })
            .catch((err) => console.error(err));
        }, 600);
      }
      if (searchTerm.length === 0) {
        getAllProducts(1, API_LIMIT, searchTerm)
          .then((result) => {
            setProducts(result);
          })
          .catch((err) => console.error(err));
      }
    },
    [getAllProducts]
  );

  return (
    <>
      <Navbar />

      <div className="StockWrapper">
        <div className="stockHeaderWrapper">
          <h1 className="stockHeader">{STOCK_ROUTE.TITLE}</h1>
        </div>
        {addSelected.selected === 1 ? <EditProductPopUp editedProductEntry={handleAddProductEntry} close={() => setAddSelected({ selected: -1 })} /> : null}
        {addSelected.selected === 0 ? (
          <AddProductPopUp
            createdProduct={addSelected.addProduct}
            currentProduct={addSelected.currentProd}
            editedProduct={addSelected.editedProduct}
            close={() => setAddSelected({ selected: -1 })}
          />
        ) : null}
        {/*    {deleteSelected && selectedOption?.value ? (
        <DeletePopUp onClick={() => handleDeleteProduct(selectedOption.value)} close={() => setDeleteSelected(false)} header="Ürünü Silmek İstediğinizden Emin misiniz?" />
      ) : null} */}
        <div className="stockInformationWrapper">
          <div className="stockDropdownWrapper">
            <ReactSelect
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-color)",
                  primary: "var(--primary-color)",
                },
              })}
              className="productDropdown"
              options={products.map((product) => {
                return {
                  value: product,
                  label: `${product.code.toUpperCase()} : ${product.name.toUpperCase()} : ${product.brand ? product.brand.toUpperCase() : ""} ${
                    product.note ? " :" + product.note.toUpperCase() : ""
                  } `,
                };
              })}
              noOptionsMessage={() => {
                if (productsLoading) {
                  return "Yükleniyor...";
                }
                return "Ürün bulunamadı";
              }}
              value={selectedOption}
              placeholder="Arama yapabilmek için en az 3 karakter giriniz"
              onInputChange={(e) => searchProducts(e)}
              onChange={handleSelectedOptionChange}
            />
            <div className="stockInfoButtonWrapper">
              <button className="addBtn" onClick={() => setAddSelected({ selected: 0, addProduct: handleAddProduct })}>
                Yeni Ürün Oluştur
              </button>
              <button
                disabled={selectedOption?.value === undefined}
                style={{ backgroundColor: "var(--secondary-color) ", marginLeft: "0.5rem" }}
                className="addBtn"
                onClick={() => {
                  setAddSelected({ selected: 0, currentProd: selectedOption?.value, editedProduct: handleEditedProduct });
                }}
              >
                Ürün Kartını Değiştir
              </button>
              <button
                className="addBtn"
                disabled={selectedOption?.value === undefined}
                onClick={() => setAddSelected({ selected: 1 })}
                style={{ backgroundColor: "#33691e", marginLeft: "0.5rem" }}
              >
                Seçili Ürüne Ekleme Yap
              </button>

              {/* <button className="deleteBtn" disabled={selectedOption?.value === undefined} onClick={() => setDeleteSelected(true)}>
            Seçili Ürünü Sil
          </button> */}
            </div>
          </div>
          {/* {selectedOption?.value !== undefined ? (
          <div className="stockInfoItemWrapper">
            <div className="stockInfoItem">Adet: {selectedOption?.value.amount}</div>
            <div className="stockInfoItem">Minimum Adet: {selectedOption?.value.minAmount}</div>
            <div className="stockInfoItem">Raf: {selectedOption?.value.shelf}</div>
            <div className="stockInfoItem">Birim Liste: {selectedOption?.value.pricePerProduct}</div>
          </div>
        ) : null} */}
        </div>
        {productsLoading ? (
          <div className="LoaderStockWrapper">
            <ThreeCircles height="80" width="80" color="var(--primary-color)" />
          </div>
        ) : null}
        {selectedOption?.value ? <StockCard updateProduct={handleEditedProductEntry} product={selectedOption.value} /> : null}
      </div>
    </>
  );
};
