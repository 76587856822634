import { useState } from "react";
import API from "../api/API";
import { WorkOrder } from "../Models/WorkOrder";

const api = new API();

const useWorkOrder = () => {
  const [workOrdersLoading, setWorkOrdersLoading] = useState<boolean>(false);

  const getAllWorkOrders = (page: number, limit: number, search: string) => {
    setWorkOrdersLoading(true);
    return api
      .getAllWorkOrders(page, limit, search)
      .then((workOrders) => {
        return workOrders;
      })
      .finally(() => setWorkOrdersLoading(false))
      .catch((err) => console.error(err));
  };

  const addWorkOrder = (workOrder: WorkOrder, customerId: number) => {
    setWorkOrdersLoading(true);
    return api
      .postWorkOrder(workOrder, customerId)
      .then((result) => {
        return api.getCustomerDetails(customerId).then((customerDetails) => {
          return customerDetails;
        });
      })
      .finally(() => setWorkOrdersLoading(false))
      .catch((err) => console.error(err));
  };

  const updateWorkOrder = (workOrder: WorkOrder, justNumberSet?: 0 | 1) => {
    setWorkOrdersLoading(true);
    return api
      .putWorkOrder(workOrder, justNumberSet)
      .then((result) => {
        return api.getCustomerDetails(workOrder._customerId).then((customerDetails) => {
          return customerDetails;
        });
      })
      .finally(() => setWorkOrdersLoading(false))
      .catch((err) => console.error(err));
  };

  const deleteWorkOrder = (workOrder: WorkOrder) => {
    setWorkOrdersLoading(true);
    return api
      .deleteWorkOrder(workOrder._id)
      .then((result) => {
        api.getCustomerDetails(workOrder._customerId).then((customerDetails) => {
          return customerDetails;
        });
      })
      .finally(() => setWorkOrdersLoading(false))
      .catch((err) => console.error(err));
  };

  const getProductsWorkOrders = (productId: number) => {
    setWorkOrdersLoading(true);
    return api
      .getWorkOrdersbyProduct(productId)
      .then((result) => {
        return result;
      })
      .finally(() => setWorkOrdersLoading(false))
      .catch((err) => console.error(err));
  };

  return { getAllWorkOrders, workOrdersLoading, updateWorkOrder, deleteWorkOrder, addWorkOrder, getProductsWorkOrders };
};

export default useWorkOrder;
