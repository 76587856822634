import classes from "./StockCalculationCard.module.scss";
import { useNavigate } from "react-router";
import { CALCULATION_DETAILS_ROUTE } from "../../Constants/ROUTE_PATH_TITLES";
import moment from "moment";
import { CalculationFirm } from "../../Models/calculationFirm";

interface IStockCalculationCard {
  _stockCalculationId: number;
  note: string;
  firm: CalculationFirm;
}

export const StockCalculationCard: React.FC<IStockCalculationCard> = ({ _stockCalculationId, note, firm }) => {
  const navigate = useNavigate();

  return (
    <div className={classes.calculationCardGeneral}>
      <div className={classes.calculationCardNameWrapper}>
        <div className={classes.calculationCardItem}>
          <div className={classes.calculationCardNameHeader}>Firma</div>
          <div className={classes.calculationCardName}>{firm.firmName}</div>
        </div>
        <div className={classes.calculationCardItem}>
          <div className={classes.calculationCardNameHeader}>Fatura No</div>
          <div className={classes.calculationCardName}>{firm.billNo}</div>
        </div>
        <div className={classes.calculationCardItem}>
          <div className={classes.calculationCardNameHeader}>Tarih</div>
          <div className={classes.calculationCardName}>{moment(firm.date).format("DD/MM/YYYY")}</div>
        </div>

        <div className={classes.calculationCardItem}>
          <div className={classes.calculationCardNameHeader}>Not</div>
          <div className={classes.calculationCardName}>{note}</div>
        </div>
        <div>
          <button style={{ marginRight: "1rem" }} onClick={() => navigate(`${CALCULATION_DETAILS_ROUTE.PATH}/${_stockCalculationId}`)} className={classes.cssbuttons_io_button}>
            Hesaplamaya Git
            <div className={classes.icon}>
              <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z" fill="currentColor"></path>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};
