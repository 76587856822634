import { ChangeEvent, FocusEvent, useState } from "react";

interface NumberInputProps {
  value: number;
  onChange: (value: number) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  step?: string;
}

export const NumberInput = ({ value, onChange, placeholder, disabled, className }: NumberInputProps) => {
  const [inputValue, setInputValue] = useState(value ? value.toString() : "");

  const formatNumber = (x: string) => {
    const [integer, decimal] = x.split(".");
    const formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return decimal ? `${formattedInteger}.${decimal}` : formattedInteger;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;

    // Allow input to have numbers, commas, and one dot
    if (/^[0-9,.]*$/.test(input)) {
      setInputValue(input);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/,/g, "");
    const numericValue = parseFloat(input);

    if (!isNaN(numericValue)) {
      onChange(numericValue);
      setInputValue(formatNumber(input));
    } else {
      setInputValue("");
    }
  };

  return (
    <input type="text" value={inputValue} onChange={handleChange} onBlur={handleBlur} placeholder={placeholder} disabled={disabled} className={className} inputMode="decimal" />
  );
};
